import React, { useState, useEffect,useRef } from "react";
import { Helmet } from "react-helmet";
import { Input, Heading, Img, Button, Text, TextArea, Switch } from "../../components";
import Header from "../../components/Header";
import { MenuItem, Menu, Sidebar } from "react-pro-sidebar";
import { useParams, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import axios from "axios";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpFromBracket } from '@fortawesome/free-solid-svg-icons';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
 const storeId = localStorage.getItem('storeID');


export default function EditChefPage() {
  const { menuItemId } = useParams();
  const navigate = useNavigate();
  const [collapsed, setCollapsed] = useState(false);
  const [menuItemName, setChefName] = useState("");
  const [menuItemDescription, setDescription] = useState("");
  const [menuItemPrice, setMenuitemprice] = useState("");
  const [image, setImage] = useState(null);
  const [imagePreview, setImagePreview] = useState("");
  const [message, setMessage] = useState("");
  const [category, setCategory] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [categories, setCategories] = useState([]);
  const [activeCategory, setActiveCategory] = useState(null);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const fileInputRef = useRef(null);
  const storeId = localStorage.getItem('storeID');
  const token = localStorage.getItem('token'); // Retrieve token from localStorage

  const handleCategorySelect = (item) => {
    setActiveCategory(item);
  };

  const handleArrowClick = (direction) => {
     if (direction === 'right' && activeCategory && !selectedCategories.includes(activeCategory)) {
       // Add the active category to selected categories if it's not already present
       setSelectedCategories((prevSelectedCategories) => [
         ...prevSelectedCategories,
         activeCategory,
       ]);
       setActiveCategory(null);  // Clear active category after adding it
     } else if (direction === 'left' && activeCategory) {
       // Remove only the active category from selected categories, one by one
       setSelectedCategories((prevSelectedCategories) => {
         return prevSelectedCategories.filter((cat) => cat.menuCategoryId !== activeCategory.menuCategoryId);
       });
       setActiveCategory(null);  // Clear active category after removing it
     }
   };


  const handleClearSelection = () => {
    setSelectedCategories([]); // Clear selected categories
    setActiveCategory(null); // Deselect any active category
  };

  useEffect(() => {
    const fetchCategories = async () => {
      if (!storeId) {
        setError("Store ID not found. Please log in again.");
        setLoading(false);
        return;
      }
      try {
        const response = await fetch(`/api/allCategories/${storeId}`, {
          headers: {
            'Authorization': `Bearer ${token}` // Include token in the request header
          }
        });
        if (!response.ok) throw new Error('Failed to fetch categories');
        const data = await response.json();
        setCategories(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchCategories();
  }, [storeId, token]);

  useEffect(() => {
    const fetchChefData = async () => {
      try {
        const response = await axios.get(`/api/${menuItemId}`, {
          headers: {
            'Authorization': `Bearer ${token}` // Include token in the request header
          }
        });

        if (response.data.status) {
          const chefData = response.data.data;
          setChefName(chefData.menuItemName);
          setDescription(chefData.menuItemDescription);
          setMenuitemprice(chefData.menuItemPrice);
          if (chefData.menuItemImage) {
            setImagePreview(chefData.menuItemImage);
          } else {
            setImagePreview('path/to/default/image.png');
          }
        } else {
          setMessage("Chef not found.");
        }
      } catch (error) {
        console.error("Error fetching chef data:", error.response || error);
        if (error.response && error.response.status === 401) {
          // Handle token expiration or authentication error
          setMessage("Authentication failed. Please log in again.");
          navigate("/login");
        } else {
          setMessage("Failed to fetch chef data. Please check your connection.");
        }
      }
    };

    fetchChefData();
  }, [menuItemId, token, storeId, navigate]);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!menuItemName || !menuItemPrice) {
      setMessage("Please fill in all fields.");
      return;
    }

    const formData = new FormData();
    formData.append("name", menuItemName);
    formData.append("description", menuItemDescription);
    formData.append("price", menuItemPrice);
    if (image) {
      formData.append("image", image);
    }

    try {
      const response = await axios.patch(`/api/update/${menuItemId}/${storeId}`, formData, {
        headers: {
          'Authorization': `Bearer ${token}`, // Include token in the request header
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.data.status) {
        navigate("/menu-items");
      } else {
        setMessage(`Error: ${response.data.reason}`);
        navigate("/menu-items");
      }
    } catch (error) {
        navigate("/menu-items");
      console.error("Error updating menu item:", error.response || error);
      if (error.response && error.response.status === 401) {
        setMessage("Authentication failed. Please log in again.");
      } else {
        setMessage("An error occurred. Please try again.");
      }
    }
  };

  const handleCancel = () => {
    navigate("/menu-items");
  };

  const handleDelete = () => {
    setShowDeleteConfirmation(true);
  };

  const confirmDelete = async () => {
    try {
      const response = await axios.delete(`/api/delete/${menuItemId}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        }
      });
      if (response.data.status) {
        navigate("/menu-items");
      } else {
        setMessage(`Error: ${response.data.reason}`);
      }
    } catch (error) {
      console.error("Error deleting menu item:", error);
      setMessage("An error occurred while deleting the item.");
    }
    setShowDeleteConfirmation(false);
  };

  const cancelDelete = () => {
    setShowDeleteConfirmation(false);
  };


    return (
      <>
        <Helmet>
          <title>Edit Menuitem</title>
          <meta
            name="description"
            content="Introduce a new dish to your menu. Use our simple interface to add menu items, set prices, and describe your culinary creations."
          />
          <link rel="icon" href="images/Sygnus logo.png" />
        </Helmet>
        <div className="w-full h-screen bg-gray-100_01 flex flex-col items-center px-[15px] py-[20px]">
              <div className="w-full max-w-full bg-white-a700 rounded-[14px] px-[26px] py-8 shadow-xs sm:p-4">
                <div className="flex items-start justify-between w-full mb-8">
                  <Heading size="headingxl" as="h1" className="text-[32px]  font-bold text-blue_gray-800 lg:text-[27px] md:text-[26px] sm:text-[24px]">
                    Edit Menu Item
                  </Heading>
                  <div className="flex flex-1 justify-end gap-[30px] self-center ">
                        <div className="flex items-center">
                         <Text as="p" className="text-[18px] font-normal text-blue_gray-800 lg:text-[15px]">Disable</Text>
                         <Switch value={true} className="ml-2" />
                         </div>

                      <Button shape="round" className="min-w-[140px] rounded-[5px] px-[34px] font-medium sm:px-4" onClick={handleSubmit} >
                         Save
                        </Button>
                      <Button color="red_400" shape="round" className="min-w-[140px] rounded-[5px] px-8 font-medium sm:px-4" onClick={handleCancel}>
                        Cancel
                      </Button>
                      <Button
                          color="red_400"
                            shape="round"
                        className="w-[60px] h-[60px] rounded-full flex items-center justify-center bg-red-400"
                        style={{ borderRadius: '60%' }}
                        onClick={handleDelete}
                      >
                     <FontAwesomeIcon icon={faTrashCan}  className="text-white text-4xl" />
                    </Button>
                    </div>
                </div>
                <div className="flex items-start ">
                  <div className="flex w-[30%] flex-col md:w-full">
                    <div>
                      <div className="flex rounded-[20px] border border-solid border-gray-400_04 bg-white-a700 p-5 w-[400px] h-[300px] flex-shrink-0">
                        {imagePreview ? (
                          <img src={imagePreview} alt="Uploaded Menu" className="h-[196px] w-[88%] object-contain" />
                        ) : (
                          <img src="images/img_layer_x0020_1.svg" alt="Menu Image" className="h-full w-full object-contain" />
                        )}
                      </div>
                     <input
                             type="file"
                             accept="image/*"
                             onChange={handleImageChange}
                             style={{ display: "none" }}
                             ref={fileInputRef}
                             id="imageUpload"
                           />
                           <Button
                             onClick={handleButtonClick}
                             className="mt-4 ml-[106px] mr-[98px] min-w-[152px] rounded-[10px] bg-cyan-a700 px-6 py-2 text-[14px] font-medium text-blue_gray-800 md:ml-0 md:mr-0"
                           >
                             <FontAwesomeIcon icon={faArrowUpFromBracket} />
                             Upload Image
                           </Button>
                    </div>
                  </div>
                  <div className="flex flex-1 flex-col gap-4 md:w-full">
                  <div className="flex flex-row items-start gap-4"> {/* Added flex-row and gap */}
    <div className="flex flex-col items-start gap-0.5" style={{ width: '600px' }}>
     <Text as="p" className="text-[18px] font-normal text-blue_gray-800 lg:text-[15px]">
       Name<span className="text-red-500"> *</span>
     </Text>
      <Input
        color="white_A700"
        size="lg"
        variant="fill"
        shape="round"
        type="text"
        name="Name Input"
        placeholder="Menu item name"
        value={menuItemName}
        onChange={(e) => setChefName(e.target.value)}
        className="self-stretch rounded-[10px] border border-gray-400_59 px-3 font-medium"
      />
    </div>

    <div className="flex flex-col items-start gap-0.5">
      <Text as="p" className="text-[18px] font-normal text-blue_gray-800 lg:text-[15px]">
        Price<span className="text-red-500"> *</span>
      </Text>
      <Input
        color="white_A700"
        size="lg"
        variant="fill"
        shape="round"
        type="text"
        name="Price Input"
        placeholder="Enter price"
        value={menuItemPrice}
        onChange={(e) => setMenuitemprice(e.target.value)}
        className="self-stretch rounded-[10px] border border-gray-400_59 px-4 font-medium"
      />
    </div>
  </div>

  <div className="flex flex-col items-start" style={{ width: '880px', height: '200px' }}>
    <Text as="p" className="text-[18px] font-normal text-gray-800 lg:text-[15px]">
      Description
    </Text>
    <textarea
      value={menuItemDescription}
      onChange={(e) => setDescription(e.target.value)}
      placeholder="Write some description here ..."
      className="self-stretch rounded-[10px] border border-gray-400_59 px-3 font-medium text-blue_gray-800"
      style={{ width: '100%', height: '100%', resize: 'none' }}
    />
  </div>
  <label>Please choose categories that this menu item should appear in. To select a category, select the category in the left box and <br></br>click right arrow button.  To deselect a category, select the category in the right box and click left arrow button.</label>
  <div className="mt-3 flex items-center gap-2.5 md:flex-col" style={{width:'600px'}}>
                  {/* Available Categories Section */}
                 <div
                   className="flex flex-1 flex-col gap-2 rounded-[10px] border border-solid border-gray-400_59 bg-white-a700 p-4 overflow-y-auto"
                   style={{ height: '200px' }}
                 >
                  <h3 className="font-bold text-lg text-blue_gray-800 text-center">Available Categories</h3>

                    {categories
                      .filter(item => !selectedCategories.includes(item))
                      .map((item) => (
                        <div
                          key={item.id}
                        className={`cursor-pointer ${activeCategory && activeCategory.menuCategoryId === item.menuCategoryId ? 'font-bold bg-cyan-a700' : ''}`} // Highlight selected category
                          onClick={() => handleCategorySelect(item)}
                        >
                          <p className="text-[18px] font-normal text-blue_gray-800 lg:text-[15px]">
                            {item.menuCategoryName || 'No Name'}
                          </p>
                        </div>
                      ))}
                  </div>


                  {/* Arrow Controls */}
              <div className="flex flex-col justify-center items-center gap-4">
                <img
                 src={`${process.env.PUBLIC_URL}/images/img_right_arrow_1.svg`}
                  alt="Move to Selected"
                  className="h-[50px] w-[50px] cursor-pointer"
                  onClick={() => handleArrowClick('right')}
                />
                <img
                  src={`${process.env.PUBLIC_URL}/images/img_left_arrow_1.svg`}
                  alt="Move to Available"
                  className="h-[50px] w-[50px] cursor-pointer"
                  onClick={() => handleArrowClick('left')}
                />
              </div>

                  {/* Selected Categories Section */}
                  <div className="flex flex-1 flex-col gap-2 rounded-[10px] border border-solid border-gray-400_59 bg-white-a700 p-4 overflow-y-auto"
                  style={{ height: '200px' }}
                  >
                    <h3 className="font-bold text-lg text-blue_gray-800 text-center">Selected Categories</h3>

                    {selectedCategories.map((item) => (
                      <div
                        key={item.id}
                      className={`cursor-pointer ${activeCategory && activeCategory.menuCategoryId === item.menuCategoryId ? 'font-bold bg-cyan-a700' : ''}`} // Highlight selected category
                        onClick={() => handleCategorySelect(item)}
                      >
                        <p className="text-[18px] font-normal text-blue_gray-800 lg:text-[15px]">
                          {item.menuCategoryName || 'No Name'}
                        </p>
                      </div>
                    ))}
                  </div>
                </div>
                </div>
                </div>

              </div>
              {showDeleteConfirmation && (
              <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
                <div className="bg-white p-4 rounded-lg shadow-lg">
                  <h2 className="text-lg font-bold">Confirm Deletion</h2>
                  <p>Are you sure you want to delete this chef?</p>
                  <div className="flex justify-end mt-4">
                    <Button onClick={confirmDelete} className="mr-2 bg-red-400 text-white">Yes, Delete</Button>
                    <Button onClick={cancelDelete}>Cancel</Button>
                  </div>
              </div>
              </div>
            )}

  </div>
 </>
    );
  }






