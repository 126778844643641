import { Helmet } from "react-helmet";
import { Button, Text, Heading } from "../../components";
import { useEffect, useState } from 'react';
import { Link, useNavigate } from "react-router-dom";

let cachedCategories = null;

export default function MenuitemsPage() {
  const [searchBarValue6, setSearchBarValue6] = useState("");
  const [collapsed, setCollapsed] = useState(false);
  const [menuItems, setMenuItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [menuItemId, setMenuItemId] = useState(null);
  const navigate = useNavigate();
  const storeId = localStorage.getItem('storeID');
  const [cachedCategories, setCachedCategories] = useState({});

  useEffect(() => {
    const fetchMenuItems = async () => {
      if (!storeId) {
        setError("Store ID not found. Please log in again.");
        setLoading(false);
        return;
      }
      if (cachedCategories[storeId]) {
        setMenuItems(cachedCategories[storeId]);
        setLoading(false);
        return;
      }
      const token = localStorage.getItem('token');

      if (!token) {
        setError('Authorization token not found. Please log in.');
        setLoading(false);
        return;
      }

      try {
        const response = await fetch(`/api/menu-items/${storeId}`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const rawData = await response.text();
        const data = JSON.parse(rawData);

        if (data.length === 0) {
          setError("No menu items available for this store.");
        } else {
          setMenuItems(data);
          setCachedCategories(prevCache => ({
            ...prevCache,
            [storeId]: data, // Cache data per storeId
          }));
        }
      } catch (error) {
        console.error('There was a problem with the fetch operation:', error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchMenuItems();
  }, [storeId]);

  const handleSave = (menuItemId) => {
    const token = localStorage.getItem('token');
    if (!token) {
      setError("Authorization token not found. Please log in.");
      navigate('/');
      return;
    }
    navigate(`/edit-menu-items/${menuItemId}`);
  };

  return (
    <>
      <Helmet>
        <title>Menu Overview - Explore Our Diverse Food Selection</title>
        <meta name="description" content="Discover our full range of menu items including salads, main courses, and desserts. Edit and manage your restaurant's offerings with ease." />
        <link rel="icon" href="images/companylogo.png" />
      </Helmet>

      {/* Outer wrapper with full height */}
      <div className="flex flex-col h-screen">

        {/* Fixed Header Section */}
        <div className="flex-shrink-0 bg-gray-100_01 px-[20px] py-[20px] shadow-md">
          <div className="flex items-center justify-between gap-5">
            <Heading size="headingxl" as="h1" className="self-end text-[32px] font-bold text-blue_gray-800 lg:text-[27px] md:text-[26px] sm:text-[24px]">
              Menu Items
            </Heading>
            <Link to="/add-menu-items">
              <Button size="xl" shape="round" className=" min-w-[166px] rounded-[5px] px-[34px] font-medium sm:px-4" style={{ margineRight: '120px' }}>
                + Add Menu Item
              </Button>
            </Link>
          </div>
        </div>

        {/* Scrollable Menu Items Section (Only This Section Should Scroll) */}
        <div className="flex-grow overflow-y-auto px-3 md:px-0 bg-gray-100_01" style={{ paddingBottom: '100px' }}>
          <div className="grid grid-cols-5 gap-[13px] gap-y-6 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1">
            {loading ? (
              <Text>Loading...</Text> // Loading indicator
            ) : error ? (
              <Text>Error: {error}</Text> // Error message
            ) : (
              menuItems.length > 0 ? (
                menuItems.map((item) => (
                  <div key={item.id} className="flex flex-col items-center justify-between bg-white-a700 shadow-xs sm:p-4 w-[300px] h-[300px] relative rounded-[14px]">
                    <div className="flex flex-col items-center justify-between px-4 py-4 w-full h-full flex-grow truncate overflow-hidden whitespace-nowrap">
                      <div className="w-[160px] h-[160px] relative overflow-hidden mb-3 ">
                        <img
                          src={item.menuItemImage || 'fallback_image.jpg'}
                          alt={item.name} // Adding alt text for accessibility
                          className="w-full h-full object-cover rounded-[10px]" // Example styling
                        />
                      </div>
                      <Text className="text-center text-[15px] text-blue_gray-800 lg:text-[20px]">
                        {item.menuItemId || 'No ID'}
                      </Text>
                      <div className="relative group">
                        <Text
                          className="text-[12px] font-normal font-semibold text-blue_gray-800 lg:text-[10px] truncate overflow-hidden whitespace-nowrap"
                        >
                          {item.menuItemName || 'No Name'}
                        </Text>
                        {/* Tooltip */}
                        <div className="absolute left-0 bottom-full mb-2 hidden w-max max-w-xs px-2 py-1 bg-black text-white rounded group-hover:block">
                          {item.menuItemName || 'No Name'}
                        </div>
                      </div>

                      <div className="flex justify-between items-center w-full">
                        <Text className="text-[18px] font-normal font-semibold text-blue_gray-800 lg:text-[15px]">
                          ₹{item.menuItemPrice || 'No Price'}
                        </Text>
                        <Button
                          className="mt-2 h-[40px] min-w-[80px] rounded-[5px] bg-cyan-a700 px-[20px] text-[14px] font-medium text-white-a700 lg:text-[13px] sm:px-4"
                          onClick={() => handleSave(item.menuItemId)}
                        >
                          Edit
                        </Button>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <Text>No menu items available.</Text>
              )
            )}
          </div>
        </div>

      </div>
    </>
  );
}
