import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

function RestaurantTable() {
  const [restaurants, setRestaurants] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const token = localStorage.getItem("token");

  // Function to toggle the status of a restaurant
  const toggleStatus = (id) => {
    setRestaurants((prevRestaurants) =>
      prevRestaurants.map((restaurant) =>
        restaurant.id === id
          ? { ...restaurant, status: !restaurant.status }
          : restaurant
      )
    );
  };

  // Fetch data from the API on component mount
  useEffect(() => {
    const fetchRestaurants = async () => {
      try {
        const response = await fetch(`/api/store/user/store`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error("Failed to fetch restaurant data");
        }

        const data = await response.json();
        console.log(data); // Log the fetched data
        setRestaurants(data); // Set data in state
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchRestaurants();
  }, []);

  // Render table rows
  const renderRows = () => {
    return restaurants.map((restaurant, index) => (
      <tr
        key={restaurant.id}
        style={{
          backgroundColor: index % 2 === 0 ? "#f9f9f9" : "#bfbfbd",
          color: index % 2 === 0 ? "#000" : "#fff",
        }}
      >
        <td style={{ border: "1px solid #ddd", padding: "8px" }}>{restaurant.username}</td>
        <td style={{ border: "1px solid #ddd", padding: "8px" }}>{restaurant.Phonenumber}</td>
        <td style={{ border: "1px solid #ddd", padding: "8px" }}>{restaurant.email}</td>
        <td style={{ border: "1px solid #ddd", padding: "8px" }}>{restaurant.storeAddress}</td>
        <td style={{ border: "1px solid #ddd", padding: "8px" }}>{restaurant.pickupCommission}</td>
        <td style={{ border: "1px solid #ddd", padding: "8px" }}>{restaurant.revenue}</td>
        <td style={{ border: "1px solid #ddd", padding: "8px" }}>{restaurant.revenue}</td>
        <td style={{ border: "1px solid #ddd", padding: "8px" }}>
          <button
            onClick={() => toggleStatus(restaurant.id)}
            style={{
              padding: "5px 10px",
              borderRadius: "3px",
              border: "none",
              backgroundColor: restaurant.status ? "#28a745" : "#dc3545",
              color: "#fff",
              cursor: "pointer",
            }}
          >
            {restaurant.status ? "Active" : "Inactive"}
          </button>
        </td>
        <td style={{ border: "1px solid #ddd", padding: "8px" }}>
          <button
            style={{
              backgroundColor: "#007bff",
              color: "white",
              padding: "5px 10px",
              border: "none",
              borderRadius: "3px",
            }}
          >
            View Analytics
          </button>
        </td>
      </tr>
    ));
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div style={{ color: "red" }}>Error: {error}</div>;
  }

  return (
    <div style={{ padding: "20px", height: "100vh", display: "flex", flexDirection: "column" }}>
      {/* Header Section */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "20px",
        }}
      >
        <h2 style={{ fontWeight: "bold" }}>Registered Restaurants</h2>
        <div>
          <button
            style={{
              marginRight: "10px",
              backgroundColor: "#ffc107",
              color: "white",
              padding: "10px 20px",
              border: "none",
              borderRadius: "5px",
            }}
          >
            Refresh
          </button>
          <Link to="/register">
            <button
              style={{
                backgroundColor: "#00aaff",
                color: "white",
                padding: "10px 20px",
                border: "none",
                borderRadius: "5px",
              }}
            >
              + Add Restaurant
            </button>
          </Link>
        </div>
      </div>

      {/* Table */}
      <div style={{ overflowY: "auto", paddingBottom: '100px'  }}>
        <table style={{ width: "100%", borderCollapse: "collapse", border: "1px solid #ddd" }}>
          <thead>
            <tr style={{ backgroundColor: "#f8f9fa" }}>
              <th
                style={{
                  position: "sticky",
                  top: 0,
                  zIndex: 2,
                  border: "1px solid #ddd",
                  padding: "8px",
                  backgroundColor: "#f8f9fa",
                }}
              >
                Restaurant Name
              </th>
              <th
                style={{
                  position: "sticky",
                  top: 0,
                  zIndex: 2,
                  border: "1px solid #ddd",
                  padding: "8px",
                  backgroundColor: "#f8f9fa",
                }}
              >
                Phone Number
              </th>
              <th
                style={{
                  position: "sticky",
                  top: 0,
                  zIndex: 2,
                  border: "1px solid #ddd",
                  padding: "8px",
                  backgroundColor: "#f8f9fa",
                }}
              >
                Email
              </th>
              <th
                style={{
                  position: "sticky",
                  top: 0,
                  zIndex: 2,
                  border: "1px solid #ddd",
                  padding: "8px",
                  backgroundColor: "#f8f9fa",
                }}
              >
                Address
              </th>
              <th
                style={{
                  position: "sticky",
                  top: 0,
                  zIndex: 2,
                  border: "1px solid #ddd",
                  padding: "8px",
                  backgroundColor: "#f8f9fa",
                }}
              >
                Delivery Commission %
              </th>
              <th
                style={{
                  position: "sticky",
                  top: 0,
                  zIndex: 2,
                  border: "1px solid #ddd",
                  padding: "8px",
                  backgroundColor: "#f8f9fa",
                }}
              >
                Pick-up Commission
              </th>
              <th
                style={{
                  position: "sticky",
                  top: 0,
                  zIndex: 2,
                  border: "1px solid #ddd",
                  padding: "8px",
                  backgroundColor: "#f8f9fa",
                }}
              >
                Revenue
              </th>
              <th
                style={{
                  position: "sticky",
                  top: 0,
                  zIndex: 2,
                  border: "1px solid #ddd",
                  padding: "8px",
                  backgroundColor: "#f8f9fa",
                }}
              >
                Status
              </th>
              <th
                style={{
                  position: "sticky",
                  top: 0,
                  zIndex: 2,
                  border: "1px solid #ddd",
                  padding: "8px",
                  backgroundColor: "#f8f9fa",
                }}
              >
                Actions
              </th>
            </tr>
          </thead>
          <tbody>{renderRows()}</tbody>
        </table>
      </div>
    </div>
  );
}

export default RestaurantTable;
