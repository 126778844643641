import React from 'react';
import { BrowserRouter as Router, Route, Routes, Outlet, useLocation } from 'react-router-dom';

// Regular Pages
import DashboardPage from './pages/Dashboard';
import LoginPage from './pages/Login';
import Forgot  from './pages/Forgotpassword'
import ChefsPage from './pages/Chefs';
import AddChefsPage from './pages/AddChef';
import EditChefPage from './pages/EditChef';
import MenuitemsPage from './pages/Menuitems';
import AddMenuitemPage from './pages/AddMenuitem';
import EditMenuItemPage from './pages/EditMenuItem';
import CategoriesPage from './pages/Categories';
import AddCategoryPage from './pages/AddCategory';
import EditCategoryPage from './pages/EditCategory';
import OrdersPage from './pages/Orders';
import Header1 from './components/Header1'; // Adjusted import path
import Sidebar1 from './components/Sidebar1';
import ChangePassword from './pages/ChangePassword';
import ApiKey from './pages/ApiKeyValue';



// Admin Pages
import AdminSidebar from './components/AdminSidebar';
import Registration from './adminPages/RegistrationPage';
import AdmindashBoard from './adminPages/AdmindashBoard';
import userdetails from './adminPages/Userdetails';
import UserRegistration from './adminPages/RestarentRegistration'



// Both Header1 components

// MainLayout component for regular pages
function MainLayout() {
  return (
    <div className="flex">
      {/* Regular Sidebar */}
      <Sidebar1 />

      {/* Main content area */}
      <div className="flex-grow">
        {/* Always render the Header1 */}
        <Header1 />

        {/* Render nested routes here */}
        <div className="p-4">
          <Outlet />
        </div>
      </div>
    </div>
  );
}

// AdminLayout component for admin pages
function AdminLayout() {
  return (
    <div className="flex">
      {/* Admin Sidebar */}
      <AdminSidebar/>

      {/* Main content area */}
      <div className="flex-grow">
        {/* Always render the Header1 */}
        <Header1 />

        {/* Render nested routes here */}
        <div className="p-4">
          <Outlet />
        </div>
      </div>
    </div>
  );
}

function App() {
  return (
    <Router>
      <Routes>
        {/* Regular Routes with Main Layout */}
        <Route element={<MainLayout />}>
          <Route path="/dashboard" element={<DashboardPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/chefs" element={<ChefsPage />} />
          <Route path="/add-chef" element={<AddChefsPage />} />
          <Route path="/edit-chef/:chefId" element={<EditChefPage />} />
          <Route path="/menu-items" element={<MenuitemsPage />} />
          <Route path="/add-menu-items" element={<AddMenuitemPage />} />
          <Route path="/edit-menu-items/:menuItemId" element={<EditMenuItemPage />} />
          <Route path="/categories" element={<CategoriesPage />} />
          <Route path="/add-categories" element={<AddCategoryPage />} />
          <Route path="/edit-categories/:menuCategoryId" element={<EditCategoryPage />} />
          <Route path="/orders" element={<OrdersPage />} />
          <Route path="/change-password" element={<ChangePassword/>}/>
          <Route path="/api-key" element={<ApiKey />} />
        </Route>

        {/* Admin Routes with Admin Layout */}
        <Route element={<AdminLayout />}>
        <Route path="/Admindashboard" element={<AdmindashBoard/>}> </Route>
        {/*  <Route path="/Admingraphpage" element={<AdminGraph/>}> </Route> */}

         <Route path="/user" element={<userdetails />} />

        <Route path="/RegistrationPage" element={<Registration/>} />
        <Route path="/register" element={<UserRegistration/>} />
          {/* Add more admin routes here */}
        </Route>

        {/* Login route outside the layout */}
        <Route path="/" element={<LoginPage />} />
        <Route path ="/forgotpassword" element={< Forgot/>}/>
      </Routes>
    </Router>
  );
}

export default App;