import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Button, Img, Text, Input, Heading } from "../../components";
import Header from "../../components/Header";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpFromBracket } from '@fortawesome/free-solid-svg-icons';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import axios from "axios";

export default function AddCategoryPage() {
  const [categoryName, setCategoryName] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState(null);
  const [imagePreview, setImagePreview] = useState("");
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
   const storeId = localStorage.getItem('storeID');
           console.log('Retrieved storeName from localStorage:', storeId); // Debugging line

           if (!storeId) {
             setMessage("Store ID not found. Please log in again.");

             return;
           }

    if (!categoryName || !description || !image) {
      setMessage("Please fill in all fields and upload an image.");
      return;
    }
    const token = localStorage.getItem('token'); // Retrieve the JWT token from localStorage
    if (!token) {
     setMessage("Authorization token not found. Please log in.");
     navigate('/login');
     return;
   }

    const formData = new FormData();
    formData.append("menuCategoryName", categoryName);
    formData.append("description", description);
    formData.append("menuCategoryImage", image);
    formData.append("storeID",storeId);

    try {
      const response = await axios.post("/categories/create", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
           'Authorization': `Bearer ${token}`,
        },
      });

      if (response.data.status) {
        navigate("/categories");
      } else {
        setMessage(`Error: ${response.data.reason}`);
      }
    } catch (error) {
      console.error("There was an error adding the category!", error);
      setMessage("An error occurred. Please try again.");
    }
  };
  const handleSave = async (e) => {
      e.preventDefault();
      await handleSubmit(e);
      navigate('/categories');
    };
 const handleCancel = () => {
      navigate("/categories");
    setCategoryName("");
    setDescription("");
    setImage(null);
    setImagePreview("");
    setMessage("");
  };

  return (
    <>
      <Helmet>
        <title>Add New Category - Expand Your Menu</title>
        <meta
          name="description"
          content="Create a new category for your restaurant's menu. Add salads, desserts, or any new offerings to attract more customers. Easy and intuitive interface."
        />
        <link rel="icon" href="images/Sygnus logo.png" />
      </Helmet>

     <div className="w-full h-screen bg-gray-100_01 flex flex-col items-center px-[20px] py-[30px]">

       <div className="w-full max-w-full bg-white-a700 rounded-[14px] px-[26px] py-8 shadow-xs sm:p-4">
         <div className="flex items-start justify-between w-full mb-8">
           <Heading
             size="headingxl"
             as="h1"
             className="text-[32px] font-bold text-blue_gray-800 lg:text-[27px] md:text-[26px] sm:text-[24px]"
           >
             Add Category
           </Heading>
           <div className="flex gap-[45px]">
             <Button
               shape="round"
               className="min-w-[140px] rounded-[5px] px-[34px] font-medium sm:px-4"
               onClick={handleSave}
             >
               Save
             </Button>
             <Button
               color="red_400"
               shape="round"
               className="min-w-[140px] rounded-[5px] px-8 font-medium sm:px-4"
               onClick={handleCancel}
             >
               Cancel
             </Button>
           </div>
         </div>

           <div className="flex items-center gap-11 w-full mb-6">

             {/* Image Preview Box - Static Size */}
             <div className="flex rounded-[20px] border border-solid border-gray-400_04 bg-white-a700 p-5 w-[400px] h-[300px] flex-shrink-0">
               <Img
                 src={imagePreview || "images/img_layer_x0020_1.svg"}
                 alt="Category Image"
                 className="h-full w-full object-contain"
               />
             </div>

             {/* Form Fields for Name and Description */}
             <div className="flex flex-1 flex-col gap-7">
               <div className="flex flex-col items-start gap-0.5">
                 <Text as="p" className="text-[18px] font-normal text-blue_gray-800 lg:text-[15px]">
                   Name<span className="text-red-500"> *</span>
                 </Text>
                 <Input
                   value={categoryName}
                   onChange={(e) => setCategoryName(e.target.value)}
                   placeholder="Category name"
                   className="w-[76%] rounded-[10px] border border-gray-400_59 px-3 font-medium"
                 />
               </div>

               <div className="flex flex-col items-start" style={{ width: '880px', height: '200px' }}>
                                   <Text as="p" className="text-[18px] font-normal text-gray-800 lg:text-[15px]">
                                     Description<span className="text-red-500"> *</span>
                                   </Text>
                                   <textarea
                                     value={description}
                                     onChange={(e) => setDescription(e.target.value)}
                                     placeholder="Write some description here ..."
                                     className="self-stretch rounded-[10px] border border-gray-400_59 px-3 font-medium text-blue_gray-800"
                                     style={{ width: '100%', height: '100%', resize: 'none' }}
                                   />
                                 </div>
             </div>
           </div>

           {/* Upload Image Button Below Image Preview */}
           <div className="flex flex-col items-start gap-4 mt-4">
             <Button
               shape="round"
               className="min-w-[228px] gap-3.5 rounded-[5px] pl-3.5 pr-[26px] font-medium"
             >
               <input
                 type="file"
                 accept="image/*"
                 onChange={handleImageChange}
                 style={{ display: "none" }}
                 id="imageUpload"
               />
                <label htmlFor="imageUpload" className="cursor-pointer flex gap-4 items-center">
                                         <FontAwesomeIcon icon={faArrowUpFromBracket} />
                                         Upload Image</label>
             </Button>
           </div>


         {message && (
           <div className={`mt-4 text-${message.includes("successfully") ? "green" : "red"}-500`}>
             {message}
           </div>
         )}
       </div>

     </div>



    </>
  );
}
