import { Helmet } from "react-helmet";
import { Button, Text, Img, Heading } from "../../components";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

export default function ChefsPage() {
  const [chefs, setChefs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Retrieve storeId and token from localStorage
  const storeId = localStorage.getItem('storeID');  // Store the storeId after login
  const token = localStorage.getItem('token'); // Retrieve the token from localStorage

  const fetchChefs = async () => {
    if (!storeId) {
      setError("Store Id not found. Please log in again.");
      setLoading(false);
      return;
    }

    if (!token) {
      setError("Token not found. Please log in again.");
      setLoading(false);
      return;
    }

    try {
      const response = await axios.get(`/api/chef/chefs/${storeId}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      if (response.data.status) {
        const chefData = Object.values(response.data.data).map((chef) => ({
          chefId: chef.id,
          chefName: chef.name,
          chefImage: chef.image,
        }));
        setChefs(chefData);
      } else {
        setError("No chefs found for this store.");
      }
    } catch (error) {
      setError("Error fetching chefs.");
      console.error("Error fetching chefs:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchChefs();
  }, [storeId, token]); // Fetch chefs when storeId or token is available

  return (
    <>
      <Helmet>
        <title>Chef Management Dashboard - Manage Your Culinary Team</title>
        <meta
          name="description"
          content="Oversee your culinary team with our Chef Management Dashboard. Easily add, edit, and manage chefs to keep your menu fresh and exciting."
        />
        <link rel="icon" href="images/Sygnus logo.png" />
      </Helmet>

      {/* Outer wrapper with full height */}
      <div className="flex flex-col h-screen">

        {/* Fixed Header Section */}
        <div className="flex-shrink-0 bg-gray-100_01 px-[20px] py-[20px] shadow-md">
          <div className="flex items-center justify-between gap-5">
            <Heading
              size="headingxl"
              as="h1"
              className="self-end text-[32px] font-bold text-blue_gray-800 lg:text-[27px] md:text-[26px] sm:text-[24px]"
            >
              Chefs
            </Heading>
            <Link to="/add-chef">
              <Button
                size="xl"
                shape="round"
                className="mr-[98px] min-w-[166px] rounded-[5px] px-[34px] font-medium sm:px-4"
              >
                + Add Chef
              </Button>
            </Link>
          </div>
        </div>

        {/* Content Section with Scrollable Area */}
        <div className="flex-grow overflow-y-auto px-3 md:px-0 bg-gray-100_01" style={{ paddingBottom: '100px' }}>
          <div className="grid grid-cols-5 gap-[15px] gap-y-6 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1">
            {chefs.map((chef) => (
              <div
                key={chef.chefId}
                className="flex flex-col items-center justify-between bg-white-a700 shadow-xs sm:p-4 w-[300px] h-[300px] relative rounded-[14px]" // Fixed square card
              >
                <div className="flex flex-col items-center justify-between px-4 py-4 w-full h-full flex-grow">
                                  {/* Image Container: Fixed Square */}
                                  <div className="w-[160px] h-[160px] relative rounded-full overflow-hidden mb-3">
                                    <Img
                    src={chef.chefImage}
                    alt={chef.chefName}
                    className="h-full w-full rounded-full object-cover"
                  />
                </div>
                  {/* Centering chefId and chefName using flex */}

                    <Text className="text-[14px] font-normal text-blue_gray-800 lg:text-[16px] text-center mb-1">
                      {chef.chefId}
                    </Text>
                    <Text
                                        className="text-[18px] font-normal text-blue_gray-800 lg:text-[15px] text-wrap max-w-[calc(20ch)] group-hover:text-cyan-700"
                                        style={{
                                          fontSize: 'clamp(14px, 4vw, 20px)', // Dynamically adjusts text size based on viewport width
                                          overflow: 'hidden',  // Prevents overflow
                                          whiteSpace: 'nowrap', // Prevents wrapping
                                          textOverflow: 'ellipsis', // Adds ellipsis for text overflow
                                          fontWeight: 'bold !important',
                                        }}
                                        title={chef.chefName} // Shows full name on hover
                                      >
                      {chef.chefName}
                    </Text>


                  <Link to={`/edit-chef/${chef.chefId}`} className="w-full flex justify-end">
                    <Button
                        className="mt-2 w-[100px] h-[30px] rounded-[5px] bg-cyan-a700 text-white-a700 text-[14px] font-medium"                    >
                      Edit
                    </Button>
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>

      </div>
    </>
  );
}
