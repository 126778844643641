import { Helmet } from "react-helmet";
import { Img, Text, Button, Input } from "../../components";
import { CloseSVG } from "../../components/Input/close.jsx";
import OrderDetailsSection from "./OrderDetailsSection";
import OrdersSection from "./OrdersSection";
import React from "react";
import { MenuItem, Menu, Sidebar, sidebarClasses } from "react-pro-sidebar";
import { Link } from "react-router-dom";

export default function OrdersPage() {
  const [searchBarValue27, setSearchBarValue27] = React.useState("");
  const [collapsed, setCollapsed] = React.useState(false);

  //use this function to collapse/expand the sidebar
  //function collapseSidebar() {
  //    setCollapsed(!collapsed)
  //}

  return (
    <>
      <Helmet>
        <title>Order Management - Track and Manage Your Orders Efficiently</title>
        <meta
          name="description"
          content="Manage your orders with ease from the dashboard. View order details, statuses, and customer information. Keep track of payments and order history for better customer service."
        />
        <link rel="icon" href="images/Sygnus logo.png" />
      </Helmet>
      <div className="flex w-full items-start gap-10 bg-gray-100_01 min-h-screen overflow-y-auto">

        <div className="flex flex-1 flex-col gap-7">

          {/* orders section */}
          <OrdersSection />

          {/* order details section */}
          <OrderDetailsSection />
        </div>
      </div>
    </>
  );
}
