import { Helmet } from "react-helmet";
import { Button, Text, Img, Heading } from "../../components";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { useNavigate } from 'react-router-dom';
let cachedCategories = null;


export default function CategoriesPage() {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();
  const storeId = localStorage.getItem('storeID');
  const [cachedCategories, setCachedCategories] = useState({});
  const [menuCategoryImage, setImageSrc] = useState(null);
  // Fetch categories from the API
 useEffect(() => {
   const fetchCategories = async () => {
     if (!storeId) {
       setError("Store ID not found. Please log in again.");
       setLoading(false);
       return;
     }
 if (cachedCategories[storeId]) {
        setCategories(cachedCategories[storeId]);
        setLoading(false);
        return;
      }
    const token = localStorage.getItem('token');

        if (!token) {
          setError('Authorization token not found. Please log in.');
          setLoading(false);
          return;
        }

     try {
       const response = await fetch(`/api/getall/${storeId}`, {
                 method: 'GET',
                 headers: {
                   'Authorization': `Bearer ${token}`,
                   'Content-Type': 'application/json',
                 },
               });

               if (response.status === 401) {
                 setError("Unauthorized! Your session has expired. Please log in again.");
                 localStorage.removeItem('token');
                 navigate('/login');
                 return;
               }
       if (!response.ok) {
         throw new Error('Network response was not ok');
       }
       const rawData = await response.text();
       console.log(rawData);
       const data = JSON.parse(rawData);
      if (data.length === 0) {
         setError("No menu items available for this store.");
       } else {
        setCategories(data);
                  setCachedCategories(prevCache => ({
                    ...prevCache,
                    [storeId]: data, // Cache data per storeId
                  }));
                }
     } catch (error) {
       console.error('There was a problem with the fetch operation:', error);
       setError(error.message);
     } finally {
       setLoading(false);
     }
   };

   fetchCategories();
 }, [storeId]);

  const handleSave = async (menuCategoryId) => {
      // Prevent default form submission
      navigate(`/edit-categories/${menuCategoryId}`);
    };


const truncateAfterCharacters = (text, charLimit) => {
  return text.length > charLimit
    ? [text.slice(0, charLimit), text.slice(charLimit)]
    : [text];
};


  return (
    <>
      <Helmet>
        <title>Menu Categories - Manage Your Restaurant's Offerings</title>
        <meta
          name="description"
          content="Explore and manage your restaurant's menu categories including salads, chefs, payment methods, and orders. Keep your menu organized with Sygnus Techlabs."
        />
        <link rel="icon" href="images/Sygnus logo.png" />
      </Helmet>

      {/* Outer wrapper with full height */}
      <div className="flex flex-col h-screen">
        {/* Fixed Header Section */}
        <div className="flex-shrink-0 bg-gray-100_01 px-[20px] py-[20px] shadow-md">
          <div className="flex items-center justify-between gap-5">
            <Heading
              size="headingxl"
              as="h1"
              className="self-end text-[32px] font-bold text-blue_gray-800 lg:text-[27px] md:text-[26px] sm:text-[24px]"
            >
              Categories
            </Heading>
            <Link to="/add-categories">
              <Button size="xl" shape="round" className=" min-w-[166px] rounded-[5px] px-[34px] font-medium sm:px-4" style={{margineRight:'120px'}}>
                + Add Category
              </Button>
            </Link>
          </div>
        </div>
        <div className="flex-grow overflow-y-auto px-3 md:px-0 bg-gray-100_01" style={{ paddingBottom: '100px' }}>
          <div className="grid grid-cols-5 gap-[15px] gap-y-6 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1">
            {loading ? (
              <Text>Loading...</Text> // Loading indicator
            ) : error ? (
              <Text>Error: {error}</Text> // Error message
            ) : (
              categories.length > 0 ? (
                categories.map((item) => (
                  <div key={item.id}  className="flex flex-col items-center justify-between bg-white-a700 shadow-xs sm:p-4 w-[300px] h-[300px] relative rounded-[14px]">
                    <div className="flex flex-col items-center justify-between px-4 py-4 w-full h-full flex-grow truncate overflow-hidden whitespace-nowrap">
                      <div className="w-[160px] h-[160px] relative overflow-hidden mb-3 ">
                      <img src={item.menuCategoryImage || 'fallback_image_url'}
                              alt={item.name} // Adding alt text for accessibility
                              className="w-full h-full object-cover rounded-[10px] "// Reduced height and width
                            />
                      </div>
                      <Text className="text-center text-[15px] text-blue_gray-800 lg:text-[20px]">
                        {item.menuCategoryId || 'No ID'}
                      </Text>
                     <div className="relative group">
                       <Text
                         className="text-[18px] font-normal text-blue_gray-800 lg:text-[15px] text-wrap max-w-[calc(20ch)] group-hover:text-cyan-700">
                           {item.menuCategoryName || 'No Name'}
                       </Text>


                     </div>

                      <div className="flex justify-between items-center w-full">
                        <Text className="text-[18px] font-normal font-semibold text-blue_gray-800 lg:text-[15px]">

                        </Text>
                       <Button className="mt-2 h-[40px] min-w-[80px] rounded-[5px] bg-cyan-a700 px-[20px] text-[14px] font-medium text-white-a700 lg:text-[13px] sm:px-4"
                               onClick={() => handleSave(item.menuCategoryId)}
                       > Edit
                       </Button>
                      </div>


                    </div>
                  </div>
                ))
              ) : (
                <Text>No menu items available.</Text>
              )
            )}
          </div>
        </div>


      </div>
    </>
  );
}
