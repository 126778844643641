import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";

export default function RegistrationPage() {

  const [formData, setFormData] = useState({
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      role: "USER",
      storeName: "",
      gstin: "",
      storeAddress: "",
      gstPercent: "",
    });

    const [message, setMessage] = useState(""); // To display success/error message
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);// Track if the form is submitting
    const [isPopupVisible, setIsPopupVisible] = useState(false);
    const [popupMessage, setPopupMessage] = useState("");


    // Handle form change (input update)
    const handleFormChange = (e) => {
      const { name, value } = e.target;
      setFormData((prevData) => ({ ...prevData, [name]: value }));
    };

    // Handle form submission
    const handleFormSubmit = async (e) => {
      e.preventDefault();

      // Check if any field is missing
      if (!formData.firstName || !formData.lastName || !formData.email || !formData.phoneNumber || !formData.storeName || !formData.gstin || !formData.storeAddress || !formData.gstPercent) {
        setMessage("Please fill in all fields.");
        return;
      }
        setIsSubmitting(true); // Disable the button and show loading state


      const formDataToSend = new FormData();
      formDataToSend.append("firstName", formData.firstName);
      formDataToSend.append("lastName", formData.lastName);
      formDataToSend.append("email", formData.email);
      formDataToSend.append("phoneNumber", formData.phoneNumber);
      formDataToSend.append("role", formData.role);
      formDataToSend.append("storeName", formData.storeName);
      formDataToSend.append("gstin", formData.gstin);
      formDataToSend.append("storeAddress", formData.storeAddress);
      formDataToSend.append("gstPercent", formData.gstPercent);

      const token = localStorage.getItem('token');
      if (!token) {
        setError('Authorization token not found. Please log in.');
        setLoading(false);
        return;
      }

try {
  const response = await fetch("/api/register", {
    method: "POST",
    body: formDataToSend,
     headers: {
     'Authorization': `Bearer ${token}`,
      },
  });

  const result = await response.text();
  if (response.ok) {
    setPopupMessage("Registration successful!");
    setFormData({
            firstName: "",
            lastName: "",
            email: "",
            phoneNumber: "",
            role: "",
            storeName: "",
            gstin: "",
            storeAddress: "",
            gstPercent: "",
          });
  } else {
    if (result.includes("email is already in use")) {
      setPopupMessage("The email is already in use. Please use a different email.");
    } else if (result.includes("phone number is already in use")) {
      setPopupMessage("The phone number is already in use. Please use a different phone number.");
    } else {
      setPopupMessage(result || "An error occurred during registration.");
    }
  }
} catch (error) {
  console.error("Error submitting form:", error);
  setPopupMessage("Failed to submit the registration. Please try again.");
} finally {
  setIsPopupVisible(true);
  setIsSubmitting(false);
}
    };
const closePopup = () => {
    setIsPopupVisible(false);
  };

  return (
    <div className="h-screen bg-gray-100 flex justify-center items-center">
      <div className="w-full max-w-4xl bg-white p-8 rounded-md shadow-lg h-full flex flex-col">
        <h2 className="text-2xl font-bold text-center mb-6">Registration</h2>
        <Link to="/RegistrationPage">
                  <button
                    style={{
                      backgroundColor: "#00aaff",
                      color: "white",
                      padding: "10px 20px",
                      border: "none",
                      borderRadius: "5px",
                    }}
                  >
                    Back
                  </button>
                  </Link>
        {isSubmitting && (
                  <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                    <div className="w-16 h-16 border-4 border-blue-500 border-t-transparent rounded-full animate-spin"></div>
                  </div>
                )}

        {/* User Details Section */}
        <div className="mb-6">
          <h3 className="text-xl font-semibold text-gray-700 mb-4">User Details</h3>
          <div className="grid grid-cols-2 gap-6">
            {/* First Column */}
            <div>
              {/* First Name */}
              <div className="mb-4">
                <label className="block text-gray-700 font-semibold mb-2">
                  First Name
                </label>
                <input
                  type="text"
                  placeholder="Enter your first name"
                  className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  value={formData.firstName}
                  onChange={handleFormChange}
                  name="firstName"
                />
              </div>
              {/* Email */}
              <div className="mb-4">
                <label className="block text-gray-700 font-semibold mb-2">
                  Email
                </label>
                <input
                  type="email"
                  placeholder="Enter your email"
                  className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  value={formData.email}
                  onChange={handleFormChange}
                  name="email"
                />
              </div>
              {/* Role */}
              <div className="mb-4">
                <label className="block text-gray-700 font-semibold mb-2">
                  Role
                </label>
                <select
                  className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  value={formData.role}
                  onChange={handleFormChange}
                  name="role"
                >
                  <option value="user">USER</option>
                </select>
              </div>
            </div>

            {/* Second Column */}
            <div>
              {/* Last Name */}
              <div className="mb-4">
                <label className="block text-gray-700 font-semibold mb-2">
                  Last Name
                </label>
                <input
                  type="text"
                  placeholder="Enter your last name"
                  className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  value={formData.lastName}
                  onChange={handleFormChange}
                  name="lastName"
                />
              </div>
              {/* Phone Number */}
              <div className="mb-4">
                <label className="block text-gray-700 font-semibold mb-2">
                  Phone Number
                </label>
                <input
                  type="tel"
                  placeholder="Enter your phone number"
                  className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  value={formData.phoneNumber}
                  onChange={handleFormChange}
                  name="phoneNumber"
                />
              </div>
            </div>
          </div>
        </div>

        {/* Store Details Section */}
        <div className="mb-6">
          <h3 className="text-xl font-semibold text-gray-700 mb-4">Store Details</h3>
          <div className="grid grid-cols-2 gap-6">
            {/* First Column */}
            <div>
              {/* Store Name */}
              <div className="mb-4">
                <label className="block text-gray-700 font-semibold mb-2">
                  Store Name
                </label>
                <input
                  type="text"
                  placeholder="Enter your store name"
                  className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  value={formData.storeName}
                  onChange={handleFormChange}
                  name="storeName"
                />
              </div>
              {/* Store Logo */}
              <div className="mb-4">
                <label className="block text-gray-700 font-semibold mb-2">
                  Store Logo
                </label>
                <input
                  type="file"
                  className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>
              {/* GSTIN */}
              <div className="mb-4">
                <label className="block text-gray-700 font-semibold mb-2">
                  GSTIN
                </label>
                <input
                  type="text"
                  placeholder="Enter your GSTIN"
                  className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  value={formData.gstin}
                  onChange={handleFormChange}
                  name="gstin"
                />
              </div>
            </div>

            {/* Second Column */}
            <div>
              {/* Store Address */}
              <div className="mb-4">
                <label className="block text-gray-700 font-semibold mb-2">
                  Store Address
                </label>
                <textarea
                  placeholder="Enter your store address"
                  className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  value={formData.storeAddress}
                  onChange={handleFormChange}
                  name="storeAddress"
                ></textarea>
              </div>
              {/* GST Percent */}
              <div className="mb-4">
                <label className="block text-gray-700 font-semibold mb-2">
                  GST Percent
                </label>
                <input
                  type="String"
                  placeholder="Enter GST percent"
                  className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  value={formData.gstPercent}
                  onChange={handleFormChange}
                  name="gstPercent"
                />
              </div>
            </div>
          </div>
        </div>

        {/* Buttons */}
        <div className="flex justify-between mt-6">
          <button
            type="reset"
            className="bg-gray-500 text-white px-6 py-2 rounded-md hover:bg-gray-600 focus:outline-none"
          >
            Clear
          </button>
          <button
            type="submit"
            className="bg-blue-500 text-white px-6 py-2 rounded-md hover:bg-blue-600 focus:outline-none"
            onClick={handleFormSubmit}
          disabled={isSubmitting} // Disable button while submitting
                    >
                      {isSubmitting ? "Submitting..." : "Register"}
                    </button>

        </div>

        {/* Message */}
     {isPopupVisible && (
               <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                 <div className="bg-white p-6 rounded-lg shadow-lg text-center">
                   <p className="text-gray-800">{popupMessage}</p>
                   <button
                     onClick={closePopup}
                     className="mt-4 bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 focus:outline-none"
                   >
                     Close
                   </button>
                 </div>
               </div>
             )}
      </div>
    </div>
  );
}
