import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Button, Img, Text, Input, Heading, Switch } from "../../components";
import Header from "../../components/Header";
import { useParams, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpFromBracket } from '@fortawesome/free-solid-svg-icons';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import axios from "axios";

export default function EditCategoryPage() {
  const { menuCategoryId } = useParams();
  const navigate = useNavigate();
  const [menuCategoryName, setMenuCategoryName] = useState("");
  const [description, setDescription] = useState("");
  const [menuCategoryImage, setMenuCategoryImage] = useState(null);
  const [menuCategoryImagePreview, setMenuCategoryImagePreview] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);
  const [message, setMessage] = useState("");
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
   const storeId = localStorage.getItem('storeID');

  // Fetch category data when the component mounts
  useEffect(() => {
    const fetchCategoryData = async () => {
      try {
        const response = await axios.get(`/categories/get/${menuCategoryId}`);
        if (response.data.status) {
          const categoryData = response.data.data;
          setMenuCategoryName(categoryData.menuCategoryName);
          setDescription(categoryData.description);
          setIsDisabled(categoryData.isDisabled);
          setMenuCategoryImagePreview(
            categoryData.menuCategoryImage
              ?categoryData.menuCategoryImage

              : 'path/to/default/image.png'
          );
        } else {
          setMessage("Category not found.");
        }
      } catch (error) {
        console.error("Error fetching category data:", error);
        setMessage("Failed to fetch category data.");
      }
    };

    fetchCategoryData();
  }, [menuCategoryId]);

  // Handle image change and set preview
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setMenuCategoryImage(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setMenuCategoryImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  // Handle form submission
  const handleSubmit = async (e) => {
      e.preventDefault();

      if (!menuCategoryName || !description) {
        setMessage("Please fill in all fields.");
        return;
      }

      const formData = new FormData();
      formData.append("menuCategoryName", menuCategoryName);
      formData.append("description", description);
      if (menuCategoryImage) {
        formData.append("image", menuCategoryImage);
      }

      try {
        const response = await axios.patch(`/categories/edit/${menuCategoryId}/${storeId}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        if (response.data.status) {
          navigate("/categories");
        } else {
             navigate("/categories");
          setMessage(`Error: ${response.data.reason}`);
        }
      } catch (error) {
        console.error("Error updating menu item:", error.response ? error.response.data : error);
        if (error.response) {
          // Log the error response and its status
          console.error("Response Status:", error.response.status);
          console.error("Response Data:", error.response.data);
          setMessage(`An error occurred: ${error.response.data.reason || 'Unknown error.'}`);
        } else {
          setMessage("An error occurred. Please try again.");
        }
      }
  };


  // Handle form reset
  const handleCancel = () => {
      navigate("/categories");
  };

  // Show delete confirmation modal
  const handleDelete = () => {
    setShowDeleteConfirmation(true);
  };

  // Confirm deletion
  const confirmDelete = async () => {
    try {
      const response = await axios.delete(`/categories/delete/${menuCategoryId}`);
      if (response.data.status) {
        navigate("/categories");
      } else {
        setMessage(`Error: ${response.data.reason}`);
      }
    } catch (error) {
      console.error("Error deleting category:", error);
      setMessage("An error occurred while deleting the category.");
    }
    setShowDeleteConfirmation(false);
  };

  // Cancel deletion
  const cancelDelete = () => {
    setShowDeleteConfirmation(false);
  };

  return (
    <>
      <Helmet>
        <title>Edit Menu Categories</title>
        <meta name="description" content="Modify category details and image for your restaurant's menu." />
        <link rel="icon" href="images/Sygnus logo.png" />
      </Helmet>
      <div className="w-full h-screen bg-gray-100_01 flex flex-col items-center px-[20px] py-[30px]">
        <div className="w-full max-w-full bg-white-a700 rounded-[14px] px-[26px] py-8 shadow-xs sm:p-4">
          <div className="flex items-start justify-between w-full mb-8">
            <Heading size="headingxl" as="h1" className="text-[32px] font-bold text-blue_gray-800 lg:text-[27px] md:text-[26px] sm:text-[24px]">
              Edit Category
            </Heading>


            <div className="flex gap-[45px]">
                   {/* Disable Toggle */}
                    <Switch
                    checked={isDisabled}
                    onChange={() => setIsDisabled(!isDisabled)}
                    className="ml-4"
                    />
              <Button
                shape="round"
                className="min-w-[140px] rounded-[5px] px-[34px] font-medium sm:px-4"
                onClick={handleSubmit}
              >
                Save
              </Button>
              <Button
                color="red_400"
                shape="round"
                className="min-w-[140px] rounded-[5px] px-8 font-medium sm:px-4"
                onClick={handleCancel}
              >
                Cancel
              </Button>
              <Button
                color="red_400"
                shape="round"
                className="w-[60px] h-[60px] rounded-full flex items-center justify-center bg-red-400"
                style={{ borderRadius: '100%' }}
                onClick={handleDelete}
              >
                 <FontAwesomeIcon icon={faTrashCan}  className="text-white text-4xl" />
              </Button>
            </div>
          </div>


            <div className="flex items-center gap-11 w-full mb-6">
              {/* Image Preview Box */}
              <div className="flex rounded-[20px] border border-solid border-gray-400_04 bg-white-a700 p-5 w-[400px] h-[300px] flex-shrink-0">
                <Img
                  src={menuCategoryImagePreview || "images/img_layer_x0020_1.svg"}
                  alt="Category Image"
                  className="h-full w-full object-contain"
                />
              </div>

              {/* Form Fields for Name and Description */}
              <div className="flex flex-1 flex-col gap-7">
                <div className="flex flex-col items-start gap-0.5">
                  <Text as="p" className="text-[18px] font-normal text-blue_gray-800 lg:text-[15px]">Name</Text>
                  <Input
                    value={menuCategoryName}
                    onChange={(e) => setMenuCategoryName(e.target.value)}
                    placeholder="Category name"
                    className="w-[76%] rounded-[10px] border border-gray-400_59 px-3 font-medium"
                  />
                </div>

                <div className="flex flex-col items-start" style={{ width: '880px', height: '200px' }}>
                                    <Text as="p" className="text-[18px] font-normal text-gray-800 lg:text-[15px]">
                                      Description
                                    </Text>
                                    <textarea
                                      value={description}
                                      onChange={(e) => setDescription(e.target.value)}
                                      placeholder="Write some description here ..."
                                      className="self-stretch rounded-[10px] border border-gray-400_59 px-3 font-medium text-blue_gray-800"
                                      style={{ width: '100%', height: '100%', resize: 'none' }}
                                    />
                                  </div>


              </div>
            </div>

            {/* Upload Image Button */}
            <div className="flex flex-col items-start gap-4 mt-4">
              <Button type="button" shape="round" className="min-w-[228px] gap-3.5 rounded-[5px] pl-3.5 pr-[26px] font-medium">
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleImageChange}
                  style={{ display: "none" }}
                  id="imageUpload"
                />
                 <label htmlFor="imageUpload" className="cursor-pointer flex gap-4 items-center">
                                          <FontAwesomeIcon icon={faArrowUpFromBracket} />
                                          Upload Image</label>
              </Button>
            </div>


          {message && (
            <div className="text-center text-red-600 mt-4">{message}</div>
          )}
        </div>

        {/* Confirm Deletion Popup */}
        {showDeleteConfirmation && (
          <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="bg-white p-4 rounded-lg shadow-lg">
                      <h2 className="text-lg font-bold">Confirm Deletion</h2>
                      <p>Are you sure you want to delete this chef?</p>
                      <div className="flex justify-end mt-4">
                        <Button onClick={confirmDelete} className="mr-2 bg-red-400 text-white">Yes, Delete</Button>
                        <Button onClick={cancelDelete}>Cancel</Button>
                      </div>
                    </div>
                  </div>
        )}
      </div>
    </>
  );
}
