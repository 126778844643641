import { Text, Img } from "./..";
import React, { useState } from "react";
import { MenuItem, Menu, Sidebar } from "react-pro-sidebar";
import { Link, useNavigate } from "react-router-dom";
import { BsGrid1X2Fill, BsFillArchiveFill, BsFillGrid3X3GapFill, BsPeopleFill, BsListCheck, BsMenuButtonWideFill, BsFillGearFill } from "react-icons/bs";

export default function AdminSidebar({ ...props }) {
  const [collapsed, setCollapsed] = useState(false);
  const navigate = useNavigate();

  return (
    <Sidebar
      {...props}
      width="300px !important"
      collapsedWidth="80px !important"
      collapsed={collapsed}
      className={`${props.className} flex flex-col h-full pt-[30px] top-0 sm:pt-4 bg-white-a700 !sticky`}
      style={{
        height: "100vh",
        position: "relative",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Img src="images/img_logo.png" alt="Logo Image" className="ml-10 h-[90px] w-[64%] object-contain" />

      <Menu
        menuItemStyles={{
          button: {
            padding: "20px 20px 20px 44px",
            gap: "25px",
            color: "#464154",
            fontWeight: 500,
            fontSize: "20px",
            [`&:hover, &.ps-active`]: { color: "#0bb5c9", fontWeight: "600 !important" },
          },
        }}
        rootStyles={{ ["&>ul"]: { gap: "0.25px" } }}
        className="mt-[52px] flex w-full flex-col self-stretch"
      >
        <Link to="/Admindashboard">
          <MenuItem icon={<BsGrid1X2Fill className="text-xl mr-3" />}>
            Admin Dashboard
          </MenuItem>
        </Link>
        <Link to="">
          <MenuItem icon={<BsFillArchiveFill className="text-xl mr-3" />}>
            Order Management
          </MenuItem>
        </Link>
        <Link to="/user">
          <MenuItem icon={<BsFillGrid3X3GapFill className="text-xl mr-3" />}>
            Content Management
          </MenuItem>
        </Link>
        <Link to="/RegistrationPage">
          <MenuItem icon={<BsPeopleFill className="text-xl mr-3" />}>
            User Management
          </MenuItem>
        </Link>
        <Link to="/reports">
          <MenuItem icon={<BsListCheck className="text-xl mr-3" />}>
            Reports
          </MenuItem>
        </Link>
        <Link to="/analytics">
          <MenuItem icon={<BsMenuButtonWideFill className="text-xl mr-3" />}>
            Analytics
          </MenuItem>
        </Link>
        <Link to="/settings">
          <MenuItem icon={<BsFillGearFill className="text-xl mr-3" />}>
            Settings
          </MenuItem>
        </Link>
      </Menu>

      {/* Footer Section */}
      {!collapsed && (
        <div className="ml-[50px] mr-[54px] mt-[50px] flex flex-col items-start gap-[18px] self-stretch">
          <Text size="body_paragraph_small" as="p" className="font-barlow text-[14px] font-normal leading-[18px] text-gray-500_02">
            <>{`Sygnus Techlabs private limited`}<br />© 2024 All Rights Reserved</>
          </Text>
          <Text size="body_paragraph" as="p" className="font-barlow text-[14px] font-normal text-gray-500_02">
            Made with <span className="text-red-500">❤️</span> by Sygnus
          </Text>
        </div>
      )}
    </Sidebar>
  );
}
