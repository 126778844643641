import React, { useState, useEffect } from "react";
import { Heading, Button, Img, Text } from "../../components";
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpFromBracket } from '@fortawesome/free-solid-svg-icons';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';

export default function OrderDetailsSection() {
  const [menuItems, setMenuItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const ordersPerPage = 8;
  const navigate = useNavigate();
  const storeId = localStorage.getItem('storeID');
  const token = localStorage.getItem('token');


  useEffect(() => {
      const fetchMenuItems = async () => {
        if (!storeId) {
          setError("Store ID not found. Please log in again.");
          setLoading(false);
          return;
        }
        if (!token) {
          setError("JWT token is missing. Please log in again.");
          setLoading(false);
          return;
        }

        try {
          const response = await fetch(`/api/orders/allOrders/${storeId}`, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`, // Include the JWT token
            },
          });

          if (!response.ok) {
            throw new Error(`Network error: ${response.status} - ${response.statusText}`);
          }

          const data = await response.json();
          setMenuItems(data);
        } catch (error) {
          console.error("There was a problem with the fetch operation:", error);
          setError(error.message);
        } finally {
          setLoading(false);
        }
      };

      fetchMenuItems();
    }, [storeId]);


  const handleDelete = async (id) => {
    const isConfirmed = window.confirm(`Are you sure you want to delete order ID ${id}?`);
    if (!isConfirmed) return;

    try {
      const response = await fetch(`/api/orders/${id}`, {
        method: 'DELETE',
         headers: {
         "Content-Type": "application/json",
          Authorization: `Bearer ${token}`, // Include the JWT token
          },
      });
      if (!response.ok) {
        throw new Error('Failed to delete the order');
      }
      setMenuItems((prevItems) => prevItems.filter(item => item.orderId !== id));
    } catch (error) {
      console.error('There was a problem with the delete operation:', error);
      setError(error.message);
    }
  };

  const indexOfLastOrder = currentPage * ordersPerPage;
  const indexOfFirstOrder = indexOfLastOrder - ordersPerPage;
  const currentOrders = menuItems.slice(indexOfFirstOrder, indexOfLastOrder);
  const totalPages = Math.ceil(menuItems.length / ordersPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const getPageNumbers = () => {
    const pages = [];
    if (totalPages <= 5) {
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      if (currentPage <= 3) {
        pages.push(1, 2, 3, 4, '...', totalPages);
      } else if (currentPage > totalPages - 3) {
        pages.push(1, '...', totalPages - 3, totalPages - 2, totalPages - 1, totalPages);
      } else {
        pages.push(1, '...', currentPage - 1, currentPage, currentPage + 1, '...', totalPages);
      }
    }
    return pages;
  };

  const ordersOnCurrentPage = currentOrders.length;

  return (
   <div className="relative p-4">
     {/* Order details section */}
     <div className="rounded-lg bg-white shadow-md p-4 mb-20">
       <div className="mb-3">
         <div className="flex flex-col items-start">
           {/* Headings */}
           <div className="flex justify-between items-center w-full mb-5">
             {['Order ID', 'Time', 'Amount', 'Customer Name', 'Order Details', 'Payment Status', 'Order Status', 'Actions'].map((heading, index) => (
               <Heading key={index} as={`h${index + 2}`} className="font-bold text-blue_gray-800 flex-2 ">
                 {heading}
               </Heading>
             ))}
           </div>

           {/* Order details */}
           <div className="flex flex-col w-full gap-4">
             {loading ? (
               <Text>Loading...</Text>
             ) : error ? (
               <Text>Error: {error}</Text>
             ) : (
               currentOrders.length > 0 ? (
                 currentOrders.map((item) => (
                   <div key={item.id} className="flex justify-between items-center w-full">
                     <Text className="flex-1">{item.orderId || 'No ID'}</Text>
                     <Text className="flex-1">
                       {item.orderTime ? new Date(item.orderTime * 1000).toLocaleString() : 'No Time'}
                     </Text>

                     <Text className="flex-1">{item.totalAmount || 'No Amount'}</Text>
                     <Text className="flex-1">{item.customerName || 'No Name'}</Text>
                     <Text className="flex-1">{item.menuItemId || 'No Item'}</Text>
                     <Text className="mt-2 rounded-md text-white px-2 flex-1">
                       <span className="bg-green-500 px-2 rounded-md">
                         {item.paymentStatus || 'No Status'}
                       </span>
                     </Text>
                     <Text className="mt-2 rounded-md text-white px-2 flex-1">
                     <span className="bg-green-500 px-2 rounded-md">
                      {item.orderStatus || 'No Status'}
                      </span>
                   </Text>
                     <Button
                       color="red_400"
                       shape="round"
                       className="w-[60px] h-[60px] rounded-full flex items-center justify-center bg-red-400"
                       style={{ borderRadius: '60%' }}
                       onClick={() => handleDelete(item.orderId)}
                     >
                       <FontAwesomeIcon icon={faTrashCan}  className="text-white text-4xl" />
                     </Button>
                   </div>
                 ))
               ) : (
                 <Text>No menu items available.</Text>
               )
             )}
           </div>

         </div>
       </div>
      {/* Total orders display - bottom-left */}
     <div className="fixed bottom-4 left-4 text-sm text-gray-500 font-bold"style={{ paddingLeft: '300px',fontFamily:'italic',fontSize:'20px'}}>
        Showing {ordersOnCurrentPage} of {menuItems.length} orders
      </div>

      {/* Pagination controls - bottom-right */}
      <div className="fixed bottom-4 right-4 flex items-center space-x-2">
        <Button
         className="border border-gray-300 text-gray-900 bg-gray-100 rounded-full text-xs flex items-center justify-center hover:bg-cyan-700 hover:text-black"
           style={{ width: '32px', height: '32px', minWidth: '32px', minHeight: '32px' }}
          disabled={currentPage === 1}
          onClick={() => handlePageChange(currentPage - 1)}
        >
          &lt;
        </Button>

        {getPageNumbers().map((page, index) => (
          <Button
            key={index}
            className={`border border-gray-300 rounded-full w-8 h-8 text-xs flex items-center justify-center ${currentPage === page ? 'bg-blue-500 text-gray-900 font-bold' : 'text-gray-600 hover:bg-cyan-a700 hover:text-black'}`}
            onClick={() => typeof page === 'number' && handlePageChange(page)}
            disabled={typeof page !== 'number'}
          >
            {page}
          </Button>
        ))}

        <Button
          className="border border-gray-300 text-gray-900 bg-gray-100 rounded-full w-8 h-8 text-xs flex items-center justify-center hover:bg-cyan-a700 hover:text-black"
          disabled={currentPage === totalPages}
          onClick={() => handlePageChange(currentPage + 1)}
        >
          &gt;
        </Button>
      </div>
    </div>
    </div>
  );
}
