import { Helmet } from "react-helmet";
import { Input, Heading, Img, Button, Text, TextArea } from "../../components";
import Header from "../../components/Header";
import React, { useRef, useState, useEffect } from "react";
import { MenuItem, Menu, Sidebar } from "react-pro-sidebar";
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpFromBracket } from '@fortawesome/free-solid-svg-icons';



export default function AddMenuItemPage() {
  const [collapsed, setCollapsed] = useState(false);
  const [uploadedImage, setUploadedImage] = useState(null);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [price, setPrice] = useState('');
  const [category, setCategory] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const fileInputRef = useRef(null);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [tasks] = useState([]);
  const [selectedTasks, setSelectedTasks] = useState([]);
  const [categories, setCategories] = useState([]);
  const [activeCategory, setActiveCategory] = useState(null);
  const [selectedCategories, setSelectedCategories] = useState([]);
   const [message, setMessage] = useState("");
   const storeId = localStorage.getItem('storeID');

  useEffect(() => {
    const fetchCategories = async () => {
         if (!storeId) {
               setError("Store ID not found. Please log in again.");
               setLoading(false);
               return;
             }
      try {
        const response = await fetch(`/api/allCategories/${storeId}`);
        if (!response.ok) throw new Error('Failed to fetch categories');
        const data = await response.json();
        setCategories(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchCategories();
  }, []);

  const handleCategorySelect = (item) => {
    setActiveCategory(item);
  };

  const handleArrowClick = (direction) => {
     if (direction === 'right' && activeCategory && !selectedCategories.includes(activeCategory)) {
       setSelectedCategories((prevSelectedCategories) => [
         ...prevSelectedCategories,
         activeCategory,
       ]);
       setActiveCategory(null);  // Clear active category after adding it
     } else if (direction === 'left' && activeCategory) {
       // Remove only the active category from selected categories, one by one
       setSelectedCategories((prevSelectedCategories) => {
         return prevSelectedCategories.filter((cat) => cat.menuCategoryId !== activeCategory.menuCategoryId);
       });
       setActiveCategory(null);  // Clear active category after removing it
     }
   };


  const handleClearSelection = () => {
    setSelectedCategories([]);
    setActiveCategory(null);
  };

  const handleCategoryClick = (item, isSelected) => {
    if (isSelected) {
      setSelectedCategories(selectedCategories.filter((cat) => cat.id !== item.id));
      setCategories([...categories, item]);
    } else {
      setCategories(categories.filter((cat) => cat.id !== item.id));
      setSelectedCategories([...selectedCategories, item]);
    }
  };

  const handleSelect = (task) => {
    if (!selectedTasks.includes(task)) {
      setSelectedTasks([...selectedTasks, task]);
    }
  };

  const handleDeselect = (task) => {
    setSelectedTasks(selectedTasks.filter((t) => t !== task));
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setUploadedImage(imageUrl);
      setSelectedFile(file);
    }
  };

 const handleSubmit = async (e) => {
   e.preventDefault();

   const storeID = localStorage.getItem('storeID');
   console.log('Retrieved storeName from localStorage:', storeID); // Debugging line

   if (!storeID) {
     setMessage("Store ID not found. Please log in again.");
     navigate('/login');
     return;
   }

   const token = localStorage.getItem('token'); // Retrieve the JWT token from localStorage
   if (!token) {
     setMessage("Authorization token not found. Please log in.");
     navigate('/login');
     return;
   }
   if(!name || !price)
   {
       setMessage("please fill all fields");
       return;
   }

   const formData = new FormData();
   formData.append("name", name);
   formData.append("description", description);
   formData.append("price", price);
   formData.append("storeID", storeID);
   const selectedCategoryNames = selectedCategories.map(item => item.menuCategoryName);
   formData.append('category', JSON.stringify(selectedCategoryNames));

   if (selectedFile) {
     formData.append("image", selectedFile);
   }

   try {
     const response = await fetch("/api/add", {
       method: "POST",
       body: formData,
       headers: {
         'Authorization': `Bearer ${token}`, // Include the token in the Authorization header
       },
     });
     if (response.ok) {
       console.log("Menu Item added successfully");
       navigate('/menu-items');
     } else {
       console.error("Error adding menu item");
     }
   } catch (error) {
     console.error("Error:", error);
     alert("An error occurred while adding the menu item.");
   }
 };
  const handleSave = async (e) => {
    e.preventDefault();
    await handleSubmit(e);
    navigate('/menu-items');
  };

  const handleAddCategory = () => {
    const newCategory = { id: categories.length + 1, menuCategoryName: category };
    setCategories([...categories, newCategory]);
    setCategory('');
  };

  return (
    <>
      <Helmet>
        <title>Add New Menu Item - Expand Your Culinary Horizons</title>
        <meta
          name="description"
          content="Introduce a new dish to your menu. Use our simple interface to add menu items, set prices, and describe your culinary creations."
        />
        <link rel="icon" href="images/Sygnus logo.png" />
      </Helmet>
      <div className="w-full h-screen bg-gray-100_01 flex flex-col items-center px-[20px] py-[30px]">
              <div className="w-full max-w-full bg-white-a700 rounded-[14px] px-[26px] py-8 shadow-xs sm:p-4">
                <div className="flex items-start justify-between w-full mb-8">
                <Heading size="headingxl" as="h1" className="text-[32px] font-bold text-blue_gray-800 lg:text-[27px] md:text-[26px] sm:text-[24px]">
                  Add Menu Item
                </Heading>
                <div className="flex flex-1 justify-end gap-[45px] self-center ">
                  <Button shape="round" onClick={handleSave} className="min-w-[140px] rounded-[5px] px-[34px] font-medium sm:px-4">
                    Save
                  </Button>
                  <Link to="/menu-items">
                    <Button color="red_400" shape="round" className="min-w-[140px] rounded-[5px] px-8 font-medium sm:px-4">
                      Cancel
                    </Button>
                  </Link>
                </div>
              </div>
              <div className="flex items-start ">
                <div className="flex w-[30%] flex-col md:w-full">
                  <div>
                    <div className="flex rounded-[20px] border border-solid border-gray-400_04 bg-white-a700 p-5 w-[400px] h-[300px] flex-shrink-0">
                      {uploadedImage ? (
                        <img src={uploadedImage} alt="Uploaded Menu" className="h-[196px] w-[88%] object-contain" />
                      ) : (
                        <img src="images/img_layer_x0020_1.svg" alt="Menu Image" className="h-full w-full object-contain" />
                      )}
                    </div>
                    <input
                      type="file"
                      ref={fileInputRef}
                      style={{ display: "none" }}
                      onChange={handleImageUpload}
                      accept="image/*"
                    />
                    <Button onClick={handleButtonClick} className="mt-4 ml-[106px] mr-[98px] min-w-[152px] rounded-[10px] bg-cyan-a700 px-6 py-2 text-[14px] font-medium text-blue_gray-800 md:ml-0 md:mr-0">
                      <FontAwesomeIcon icon={faArrowUpFromBracket} />
                      Upload Image
                    </Button>
                  </div>
                </div>
                <div className="flex flex-1 flex-col gap-4 md:w-full">
                <div className="flex flex-row items-start gap-4"> {/* Added flex-row and gap */}
  <div className="flex flex-col items-start gap-0.5" style={{ width: '600px' }}>
    <Text as="p" className="text-[18px] font-normal text-blue_gray-800 lg:text-[15px]">
       Name<span className="text-red-500"> *</span>
    </Text>
    <Input
      color="white_A700"
      size="lg"
      variant="fill"
      shape="round"
      type="text"
      name="Name Input"
      placeholder="Menu item name"
      value={name}
      onChange={(e) => setName(e.target.value)}
      className="self-stretch rounded-[10px] border border-gray-400_59 px-3 font-medium"
    />
  </div>

  <div className="flex flex-col items-start gap-0.5">
    <Text as="p" className="text-[18px] font-normal text-blue_gray-800 lg:text-[15px]">
      Price<span className="text-red-500"> *</span>
    </Text>
    <Input
      color="white_A700"
      size="lg"
      variant="fill"
      shape="round"
      type="text"
      name="Price Input"
      placeholder="Enter price"
      value={price}
      onChange={(e) => setPrice(e.target.value)}
      className="self-stretch rounded-[10px] border border-gray-400_59 px-4 font-medium"
    />
  </div>
</div>

<div className="flex flex-col items-start" style={{ width: '880px', height: '200px' }}>
  <Text as="p" className="text-[18px] font-normal text-gray-800 lg:text-[15px]">
    Description
  </Text>
  <textarea
    value={description}
    onChange={(e) => setDescription(e.target.value)}
    placeholder="Write some description here ..."
    className="self-stretch rounded-[10px] border border-gray-400_59 px-3 font-medium text-blue_gray-800"
    style={{ width: '100%', height: '100%', resize: 'none' }}
  />
</div>
<label>Please choose categories that this menu item should appear in. To select a category, select the category in the left box and<br></br> click right arrow button.  To deselect a category, select the category in the right box and click left arrow button.</label>
<div className="mt-3 flex items-center gap-2.5 md:flex-col" style={{width:'600px'}}>
                {/* Available Categories Section */}
                <div className="flex flex-1 flex-col gap-2 rounded-[10px] border border-solid border-gray-400_59 bg-white-a700 p-4 overflow-y-auto" style={{ height: '200px' }}>
                  <h3 className="font-bold text-lg text-blue_gray-800">Available Categories</h3>
                  {categories
                    .filter(item => !selectedCategories.includes(item))
                    .map((item) => (
                      <div
                        key={item.id}
                        className={`cursor-pointer ${activeCategory && activeCategory.menuCategoryId === item.menuCategoryId ? 'font-bold bg-cyan-a700' : ''}`} // Highlight selected category
                        onClick={() => handleCategorySelect(item)}
                      >
                        <p className="text-[18px] font-normal text-blue_gray-800 lg:text-[15px]">
                          {item.menuCategoryName || 'No Name'}
                        </p>
                      </div>
                    ))}
                </div>

                {/* Arrow Controls */}
                <div className="flex flex-col justify-center items-center gap-4">
                  <img
                    src="images/img_right_arrow_1.svg"
                    alt="Move to Selected"
                    className="h-[50px] w-[50px] cursor-pointer"
                    onClick={() => handleArrowClick('right')}
                  />
                  <img
                    src="images/img_left_arrow_1.svg"
                    alt="Move to Available"
                    className="h-[50px] w-[50px] cursor-pointer"
                    onClick={() => handleArrowClick('left')}
                  />
                </div>

                {/* Selected Categories Section */}
                <div className="flex flex-1 flex-col gap-2 rounded-[10px] border border-solid border-gray-400_59 bg-white-a700 p-4 overflow-y-auto" style={{ height: '200px' }}>
                  <h3 className="font-bold text-lg text-blue_gray-800">Selected Categories</h3>
                  {selectedCategories.map((item) => (
                    <div
                      key={item.id}
                      className={`cursor-pointer ${activeCategory && activeCategory.menuCategoryId === item.menuCategoryId ? 'font-bold bg-cyan-a700' : ''}`} // Highlight selected category
                      onClick={() => handleCategorySelect(item)}
                    >
                      <p className="text-[18px] font-normal text-blue_gray-800 lg:text-[15px]">
                        {item.menuCategoryName || 'No Name'}
                      </p>
                    </div>
                  ))}
                </div>
              </div>

            </div>

</div>

              </div>
              </div>


    </>
  );
}
