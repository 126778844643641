import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Button, Img, Text, Input, Heading } from "../../components";
import Header from "../../components/Header";
import { MenuItem, Menu, Sidebar } from "react-pro-sidebar";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpFromBracket } from '@fortawesome/free-solid-svg-icons';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import axios from "axios";
import { useNavigate } from "react-router-dom"; // Import useNavigate


export default function AddChefPage() {
  const [collapsed, setCollapsed] = useState(false);
  const [chefName, setChefName] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState(null);
  const [imagePreview, setImagePreview] = useState("");
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  // Handle image change and set preview
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!chefName) {
        setMessage("please fill all fields");
      return;
    }

    // Retrieve storeId and token from localStorage
    const storeId = localStorage.getItem('storeID');
    const token = localStorage.getItem('token'); // Retrieve token

    if (!storeId) {
      setMessage("Store Id not found. Please log in again.");
      // Redirect to login or handle the issue accordingly
      return;
    }

    if (!token) {
      setMessage("Token not found. Please log in again.");
      // Redirect to login or handle the issue accordingly
      return;
    }

    const formData = new FormData();
    formData.append("chefName", chefName);
    formData.append("description", description);
    formData.append("image", image);
    formData.append("storeId", storeId);

    try {
      const response = await fetch("/api/chef/create", {
        method: 'POST',
        body: formData,
        headers: {
          'Authorization': `Bearer ${token}` // Include token in the request header
        }
      });

      const result = await response.json();

      if (result.status) {
        setMessage("Chef added successfully.");
        navigate("/chefs");
      } else {
        setMessage(`Error: ${result.reason}`);
      }
    } catch (error) {
      console.error("There was an error adding the chef!", error);
      setMessage("An error occurred. Please try again.");
    }
  };

  // Handle form reset
  const handleCancel = () => {
    navigate("/chefs");
    setChefName("");
    setDescription("");
    setImage(null);
    setImagePreview("");
    setMessage("");
  };

  return (
    <>
      <Helmet>
        <title>Add New Chef - Expand Your Culinary Team</title>
        <meta
          name="description"
          content="Introduce new talent to your kitchen with our Add Chef feature. Save time with a simple interface to add chef profiles and specialties to your restaurant's team."
        />
        <link rel="icon" href="images/Sygnus logo.png" />
      </Helmet>
      <div className="w-full h-screen bg-gray-100_01 flex flex-col items-center px-[20px] py-[30px]">

             <div className="w-full max-w-full bg-white-a700 rounded-[14px] px-[26px] py-8 shadow-xs sm:p-4">
               <div className="flex items-start justify-between w-full mb-8">
                 <Heading
                   size="headingxl"
                   as="h1"
                   className="text-[32px] font-bold text-blue_gray-800 lg:text-[27px] md:text-[26px] sm:text-[24px]"
                 >
                   Add Chef
                 </Heading>
                 <div className="flex gap-[45px]">
                   <Button
                     shape="round"
                     className="min-w-[140px] rounded-[5px] px-[34px] font-medium sm:px-4"
                     onClick={handleSubmit}
                   >
                     Save
                   </Button>
                   <Button
                     color="red_400"
                     shape="round"
                     className="min-w-[140px] rounded-[5px] px-8 font-medium sm:px-4"
                     onClick={handleCancel}
                   >
                     Cancel
                   </Button>
                 </div>
               </div>


                 <div className="flex items-center gap-11 w-full mb-6">

                   {/* Image Preview Box - Static Size */}
                   <div className="flex rounded-[20px] border border-solid border-gray-400_04 bg-white-a700 p-5 w-[400px] h-[300px] flex-shrink-0">
                     <Img
                       src={imagePreview || "images/img_layer_x0020_1.svg"}
                       alt="Category Image"
                       className="h-full w-full object-contain"
                     />
                   </div>

                   {/* Form Fields for Name and Description */}
                   <div className="flex flex-1 flex-col gap-7">
                     <div className="flex flex-col items-start gap-0.5">
                       <Text as="p" className="text-[18px] font-normal text-blue_gray-800 lg:text-[15px]">
                         Name
                       </Text>
                       <Input
                         value={chefName}
                         onChange={(e) => setChefName(e.target.value)}
                         placeholder="Category name"
                         className="w-[76%] rounded-[10px] border border-gray-400_59 px-3 font-medium"
                       />
                     </div>

                     <div className="flex flex-col items-start " style={{ width: '880px', height: '200px' }} >
                                         <Text as="p" className="text-[18px] font-normal text-gray-800 lg:text-[15px]">
                                           Description
                                         </Text>
                                         <textarea
                                           value={description}
                                           onChange={(e) => setDescription(e.target.value)}
                                           placeholder="Write some description here..."
                                           className="self-stretch rounded-[10px] border border-gray-400_59 px-3 font-medium text-blue_gray-800 focus:outline-none focus:ring-0"
                                           style={{ width: '100%', height: '100%', resize: 'none' }}
                                         />
                                       </div>
                   </div>
                 </div>

                 {/* Upload Image Button Below Image Preview */}
                 <div className="flex flex-col items-start gap-4 mt-4">
                   <Button
                     shape="round"
                     className="min-w-[228px] gap-3.5 rounded-[5px] pl-3.5 pr-[26px] font-medium"
                   >
                     <input
                       type="file"
                       accept="image/*"
                       onChange={handleImageChange}
                       style={{ display: "none" }}
                       id="imageUpload"
                     />
                     <FontAwesomeIcon icon={faArrowUpFromBracket} />
                     <label htmlFor="imageUpload" className="cursor-pointer">Upload Image</label>
                   </Button>
                 </div>


               {message && (
                 <div className={`mt-4 text-${message.includes("successfully") ? "green" : "red"}-500`}>
                   {message}
                 </div>
               )}
             </div>

           </div>
    </>
  );
}
