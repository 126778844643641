import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Button, Img, Input, Text, Heading, Switch } from "../../components";
import { useParams, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpFromBracket } from '@fortawesome/free-solid-svg-icons';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import axios from "axios";

export default function EditChefPage() {
  const { chefId } = useParams();
  const navigate = useNavigate();
  const [chefName, setChefName] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState(null);
  const [imagePreview, setImagePreview] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);
  const [message, setMessage] = useState("");
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const storeId = localStorage.getItem('storeID');
  const token = localStorage.getItem('token'); // Retrieve token from localStorage

  // Fetch chef data when the component mounts
  useEffect(() => {
    const fetchChefData = async () => {
      try {
        console.log("storeId from localStorage: ", storeId); // Add this log
        if (!storeId) {
          setMessage("Store ID is missing in localStorage.");
          return;
        }
        if (!token) {
          setMessage("Token is missing. Please log in again.");
          return;
        }

        const response = await axios.get(`/api/chef/get/${chefId}?storeId=${storeId}`, {
          headers: {
            'Authorization': `Bearer ${token}`, // Include token in the request header
          },
        });
        console.log("API Response:", response.data); // Log the response to see what's returned

        if (response.data.status) {
          const chefData = response.data.data;
          setChefName(chefData.chefName);
          setDescription(chefData.description);
          setIsDisabled(chefData.isDisabled);
          setImagePreview(
            chefData.chefImage
              ? chefData.chefImage
              : 'path/to/default/image.png'
          );
        } else {
          setMessage("Chef not found.");
        }
      } catch (error) {
        console.error("Error fetching chef data:", error);
        if (error.response && error.response.status === 401) {
          setMessage("Authentication failed. Please log in again.");
          navigate("/login");
        } else {
          setMessage("Failed to fetch chef data.");
        }
      }
    };

    fetchChefData();
  }, [chefId, storeId, token, navigate]);

  // Handle image change and set preview
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!chefName) {
      setMessage("Please fill in all fields.");
      return;
    }

    const formData = new FormData();
    formData.append("name", chefName);
    formData.append("description", description);
    if (image) {
      formData.append("image", image);
    }

    try {
      const response = await axios.patch(`/api/chef/edit/${chefId}/${storeId}`, formData, {
        headers: {
          "Authorization": `Bearer ${token}`, // Include token in the request header
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.data.status) {
        navigate("/chefs");
      } else {
        const errorReason = response.data.reason || "Unknown error occurred"; // Default message if no reason is provided
        setMessage(`Error: ${errorReason}`);
        navigate("/chefs");
      }
    } catch (error) {
      console.error("Error updating chef:", error);
      if (error.response && error.response.status === 401) {
        setMessage("Authentication failed. Please log in again.");
        navigate("/login");
      } else {
        setMessage("An error occurred. Please try again.");
      }
    }
  };

  // Handle form reset
  const handleCancel = () => {
    navigate("/chefs");
    setChefName("");
    setDescription("");
    setImage(null);
    setImagePreview("");
    setMessage("");
  };

  // Show delete confirmation modal
  const handleDelete = () => {
    setShowDeleteConfirmation(true);
  };

  // Confirm deletion
  const confirmDelete = async () => {
    try {
      const response = await axios.delete(`/api/chef/delete/${chefId}`, {
        headers: {
          'Authorization': `Bearer ${token}`, // Include token in the request header
        },
      });
      if (response.data.status) {
        navigate("/chefs");
      } else {
        setMessage(`Error: ${response.data.reason}`);
        navigate("/chefs");
      }
    } catch (error) {
      console.error("Error deleting chef:", error);
      if (error.response && error.response.status === 401) {
        setMessage("Authentication failed. Please log in again.");
        navigate("/");
      } else {
        setMessage("An error occurred while deleting the chef.");
      }
    }
    setShowDeleteConfirmation(false);
  };

  // Cancel deletion
  const cancelDelete = () => {
    setShowDeleteConfirmation(false);
  };
  return (
    <>
      <Helmet>
        <title>Edit Chef Profile - Update Chef Details</title>
        <meta
          name="description"
          content="Keep your chef profiles up-to-date with our Edit Chef tool. Modify chef information, specialties, and images to accurately reflect your team's capabilities and offerings."
        />
        <link rel="icon" href="images/Sygnus logo.png" />
      </Helmet>

      <div className="w-full h-screen bg-gray-100_01 flex flex-col items-center px-[20px] py-[30px]">
              <div className="w-full max-w-full bg-white-a700 rounded-[14px] px-[26px] py-8 shadow-xs sm:p-4">
                <div className="flex items-start justify-between w-full mb-8">
                  <Heading size="headingxl" as="h1" className="text-[32px] font-bold text-blue_gray-800 lg:text-[27px] md:text-[26px] sm:text-[24px]">
                    Edit Chef
                  </Heading>


                  <div className="flex gap-[45px]">
                         {/* Disable Toggle */}
                          <Switch
                          checked={isDisabled}
                          onChange={() => setIsDisabled(!isDisabled)}
                          className="ml-4"
                          />
                    <Button
                      shape="round"
                      className="min-w-[140px] rounded-[5px] px-[34px] font-medium sm:px-4"
                      onClick={handleSubmit}
                    >
                      Save
                    </Button>
                    <Button
                      color="red_400"
                      shape="round"
                      className="min-w-[140px] rounded-[5px] px-8 font-medium sm:px-4"
                      onClick={handleCancel}
                    >
                      Cancel
                    </Button>
                    <Button
                      color="red_400"
                      shape="round"
                      className="w-[60px] h-[60px] rounded-full flex items-center justify-center bg-red-400"
                      style={{ borderRadius: '100%' }}
                      onClick={handleDelete}
                    >
                     <FontAwesomeIcon icon={faTrashCan}  className="text-white text-4xl" />
                    </Button>
                  </div>
                </div>


                  <div className="flex items-center gap-11 w-full mb-6">
                    {/* Image Preview Box */}
                    <div className="flex rounded-[20px] border border-solid border-gray-400_04 bg-white-a700 p-5 w-[400px] h-[300px] flex-shrink-0">
                      <Img
                        src={imagePreview || "images/img_layer_x0020_1.svg"}
                        alt="Chef Image"
                        className="h-full w-full object-contain"
                      />
                    </div>

                    {/* Form Fields for Name and Description */}
                    <div className="flex flex-1 flex-col gap-7">
                      <div className="flex flex-col items-start gap-0.5">
                        <Text as="p" className="text-[18px] font-normal text-blue_gray-800 lg:text-[15px]">Name</Text>
                        <Input
                          value={chefName}
                          onChange={(e) => setChefName(e.target.value)}
                          placeholder="Chef name"
                          className="w-[76%] rounded-[10px] border border-gray-400_59 px-3 font-medium"
                        />
                      </div>

                      <div className="flex flex-col items-start" style={{ width: '880px', height: '200px' }}>
                                          <Text as="p" className="text-[18px] font-normal text-gray-800 lg:text-[15px]">
                                            Description
                                          </Text>
                                          <textarea
                                            value={description}
                                            onChange={(e) => setDescription(e.target.value)}
                                            placeholder="Write some description here ..."
                                            className="self-stretch rounded-[10px] border border-gray-400_59 px-3 font-medium text-blue_gray-800"
                                            style={{ width: '100%', height: '100%', resize: 'none' }}
                                          />
                                        </div>

                    </div>
                  </div>

                  {/* Upload Image Button */}
                  <div className="flex flex-col items-start gap-40 mt-4">
                    <Button type="button" shape="round" className="min-w-[228px] gap-3.5 rounded-[5px] pl-3.5 pr-[26px] font-medium">
                      <input
                        type="file"
                        accept="image/*"
                        onChange={handleImageChange}
                        style={{ display: "none" }}
                        id="imageUpload"
                      />
                      <label htmlFor="imageUpload" className="cursor-pointer flex gap-4 items-center">
                          <FontAwesomeIcon icon={faArrowUpFromBracket} />
                          Upload Image</label>
                    </Button>
                  </div>
              </div>

              {/* Confirm Deletion Popup */}
              {showDeleteConfirmation && (
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
                          <div className="bg-white p-4 rounded-lg shadow-lg">
                            <h2 className="text-lg font-bold">Confirm Deletion</h2>
                            <p>Are you sure you want to delete this chef?</p>
                            <div className="flex justify-end mt-4">
                              <Button onClick={confirmDelete} className="mr-2 bg-red-400 text-white">Yes, Delete</Button>
                              <Button onClick={cancelDelete}>Cancel</Button>
                            </div>
                          </div>
                        </div>
              )}
            </div>



    </>
  );
}
