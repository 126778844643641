import React, { useState, useEffect } from 'react';
import { Helmet } from "react-helmet";
import { Text, Img, Button, Heading, CheckBox, Input } from "../../components";
import { useNavigate, Link } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import '../../styles/index.css';
import { FaEye, FaEyeSlash } from "react-icons/fa";

export default function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loginError, setLoginError] = useState('');
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  const isTokenValid = (token) => {
    try {
      const decoded = jwtDecode(token);
      return decoded.exp * 1000 > Date.now(); // Check if the token has expired
    } catch (error) {
      return false; // Invalid token
    }
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    const Username = localStorage.getItem('username')
    const role = localStorage.getItem('role')
    if (token && isTokenValid(token)) {
      if (role === 'ADMIN') {
        navigate('/Admindashboard')
      } else {
        navigate('/dashboard');
      }
    }
  }, [navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoginError('');

    try {
      const response = await fetch('/api/auth/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password }),
      });
      if (!response.ok) {
        const errorText = await response.text();
        setLoginError(errorText || 'Login failed');
        return;
      }
      const data = await response.json();
      if (!data.token || !data.username || !data.storeId || !data.role) {
        setLoginError('Login failed: No token or username returned');
        return;
      }
      localStorage.setItem('token', data.token);
      localStorage.setItem('username', data.username);
      localStorage.setItem('storeID', data.storeId);
      localStorage.setItem('role', data.role);



      const token = localStorage.getItem('token');
      if (token && isTokenValid(token)) {
        const destination = data.role === "ADMIN" ? '/Admindashboard' : '/dashboard';
        navigate(destination);
      } else {
        setLoginError('Login failed: Invalid token');
      }

    } catch (error) {
      console.error('Login error:', error);
      setLoginError('An error occurred. Please try again.');
    }
  };

  const togglePasswordVisibility = () => {
      setShowPassword((prevState) => !prevState);
    };

  return (
    <>
      <Helmet>
        <title>Admin Login - Sygnus Techlabs</title>
        <meta
          name="description"
          content="Sign in to the Admin Dashboard at Sygnus Techlabs. Enter your username and password to access your account. Secure and user-friendly admin login."
        />
        <link rel="icon" href="images/Sygnus logo.png" />
      </Helmet>
      <div
  className="flex w-full items-center lg:flex-row md:flex-col min-h-screen overflow-hidden"
  style={{ backgroundColor: '#00838F' }}
>
  {/* Left Section */}
  <div className="flex flex-1 flex-col items-start lg:w-1/2 md:w-full md:px-5">
    <div className="flex flex-wrap w-[90%] flex-col items-center gap-6 bg-white-a700 px-10 py-20 lg:w-full lg:py-8 md:w-full sm:gap-4 sm:p-4 min-h-screen">
      {/* Logo and Dashboard Heading */}
      <div className="flex w-full flex-col items-center gap-4 lg:w-full md:items-center">
        <Img
          src="images/img_logo_1.png"
          alt="Logo Image"
          className="h-[64px] w-auto object-contain sm:h-[48px] md:h-[64px] lg:h-auto"
        />
        <Heading
          size="text3xl"
          as="h1"
          className="text-center font-barlow text-[24px] font-medium text-gray-400_02 lg:text-[20px]"
        >
          Admin Dashboard
        </Heading>
      </div>
      {/* Sign-in Section */}
      <div className="flex flex-col gap-6 w-full max-w-lg lg:w-full">
        <Heading
          size="heading2xl"
          as="h2"
          className="text-center text-[42px] font-bold text-blue_gray-800 lg:text-[36px]"
        >
          Sign in
        </Heading>
        <form
          onSubmit={handleSubmit}
          className="flex flex-col gap-4 w-full max-w-md mx-auto"
        >
          {/* Username Section */}
          <div className="flex flex-col gap-2">
            <Heading
              size="texts"
              as="h3"
              className="text-[14px] font-medium text-gray-800_01"
            >
              Username
            </Heading>
            <Input
              type="text"
              id="username"
              placeholder="Enter your username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              className="w-full rounded-lg px-4 py-2 border"
              required
            />
          </div>
          {/* Password Section */}
          <div className="flex flex-col gap-2">
            <Heading
              size="texts"
              as="h4"
              className="text-[14px] font-medium text-gray-800_01"
            >
              Password
            </Heading>
            <div className="relative w-full">
                  <input
                    type={showPassword ? "text" : "password"}
                    id="password"
                    placeholder="Enter your password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="w-full rounded-lg px-4 py-2 border"
                    required
                  />
                  <span
                    onClick={togglePasswordVisibility}
                    className="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer text-gray-500"
                  >
                    {showPassword ? <FaEyeSlash /> : <FaEye />}
                  </span>
                </div>
          </div>
          {/* Remember Me & Forgot Password */}
          <div className="flex justify-between items-center sm:flex-col sm:gap-2">
            <CheckBox
              name="Remember Checkbox"
              label="Remember me"
              id="RememberCheckbox"
              className="gap-2 text-[14px] font-medium text-gray-800_01"
            />
            <Link to="/forgotpassword">
              <Heading
                size="texts"
                as="h5"
                className="text-[14px] font-medium text-cyan-a700"
              >
                Forgot Password
              </Heading>
            </Link>
          </div>
          {/* Submit Button */}
          <Button
            type="submit"
            size="4xl"
            className="w-full rounded-lg px-4 py-2 font-medium"
          >
            Sign in
          </Button>
          {/* Error Message */}
          {loginError && (
            <div className="mt-2 text-center text-red-400">
              Sign in failed! Please check your username and password.
            </div>
          )}
        </form>
      </div>
    </div>
  </div>
  {/* Right Section */}
  <div className="mb-8 flex  w-[44%] flex-col lg:flex-row justify-center items-center px-5 lg:px-8">
  {/* Left Section */}
  <div className="relative flex flex-1 flex-col items-center justify-center  md:w-full">
    <div className="ml-16 mr-24 flex items-center justify-between gap-5 self-stretch md:mx-0 sm:flex-col sm:items-center sm:gap-6">
      <Img
        src="images/img_cashier_1.svg"
        alt="Cashier Image"
        className="mb-2.5 h-[226px] w-[226px] self-end sm:mb-0 sm:h-[150px] sm:w-[150px] sm:mx-auto"

      />
      <Img
        src="images/img_kiosk_1.svg"
        alt="Kiosk Image"
        className="h-[310px] w-[310px] sm:h-[180px] sm:w-[180px] sm:mx-auto"

      />
    </div>
  </div>

  {/* Footer Text Section */}
  <div className="absolute bottom-0 right-0 flex flex-col justify-end p-5">
  <div className="mt-10 flex flex-col items-center lg:mt-0 sm:items-start">
    <Text
      size="body_paragraph_small"
      as="p"
      className="text-center sm:text-left font-barlow text-[16px] font-bold leading-[18px] text-gray-500_02"
    >
      Sygnus Techlabs Private Limited<br />
      © 2024 All Rights Reserved
    </Text>
    <Text
      size="body_paragraph"
      as="p"
      className="mt-5 text-center sm:text-left font-barlow text-[14px] font-normal text-gray-500_02"
    >
      Made with <span className="text-red-500">❤️</span> by Sygnus
    </Text>
  </div>
</div>
</div>
</div>
    </>
  );
}
