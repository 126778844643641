import { useEffect, useState } from 'react';

const APIKeyComponent = () => {
  const [apiKey, setApiKey] = useState(null); // Use null to handle no data initially
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Function to fetch API key
  const fetchApiKey = async () => {
    const token = localStorage.getItem('token');

    if (!token) {
      setError('Authorization token not found. Please log in.');
      setLoading(false);
      return;
    }

    try {
      const response = await fetch(`/api/store/apiValues`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const rawData = await response.text();
      const data = JSON.parse(rawData);

      if (data.length === 0) {
        setError('No API key available for this store.');
      } else {
        setApiKey(data[0]); // Extract the first item
      }
    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  // Fetch API key initially and update every 10 seconds
  useEffect(() => {
    fetchApiKey(); // Initial fetch
    const intervalId = setInterval(() => {
      fetchApiKey(); // Re-fetch every 10 seconds
    }, 10000); // 10 seconds interval

    // Clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, []);

 const handleRegenerate = async (id) => {
     const token = localStorage.getItem('token');

     if (!token) {
       setError('Authorization token not found. Please log in.');
       return;
     }

     setLoading(true);

     try {
       const response = await fetch(`/api/store/apikeyid/${id}/refresh`, {
         method: 'PATCH',
         headers: {
           'Authorization': `Bearer ${token}`,
           'Content-Type': 'application/json',
         },
         body: JSON.stringify({ apiKey: true }),
       });

       if (!response.ok) {
         throw new Error('Failed to regenerate API key.');
       }

       const data = await response.json();
       setApiKey(data.apiKey); // Update API key in the state to trigger a re-render
     } catch (error) {
       console.error('Error regenerating API key:', error);
       setError(error.message);
     } finally {
       setLoading(false);
     }
   };

   // Render loading and error states
   if (loading) {
     return <div>Loading...</div>;
   }

   if (error) {
     return <div className="text-red-500">{error}</div>;
   }

  // Render the table if apiKey exists
  return (
    <div className="p-8 bg-gray-100 min-h-screen">
      <div className="max-w-4xl mx-auto bg-white p-6 rounded-lg shadow-md">
        <header className="mb-6 text-center">
          <h1 className="text-3xl font-semibold">API Key</h1>
        </header>
        {apiKey ? (
          <table className="min-w-full table-auto">
            <thead>
              <tr className="bg-gray-50">
                <th className="py-3 px-4 text-left text-sm font-medium text-gray-700">Key Id</th>
                <th className="py-3 px-4 text-left text-sm font-medium text-gray-700">Created At</th>
                <th className="py-3 px-4 text-left text-sm font-medium text-gray-700">Expiry</th>
                <th className="py-3 px-4 text-left text-sm font-medium text-gray-700">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr className="border-t">
                <td className="py-3 px-4 text-sm text-gray-800">{apiKey.apiKey}</td>
                <td className="py-3 px-4 text-sm text-gray-800">
                  {apiKey.unixTime ? new Date(apiKey.unixTime * 1000).toLocaleString() : 'N/A'}
                </td>
                <td className="py-3 px-4 text-sm text-gray-800">
                  {apiKey.Expiry ? new Date(apiKey.Expiry * 1000).toLocaleString() : 'N/A'}
                </td>
                <td className="py-3 px-4">
                  <button
                    onClick={() => handleRegenerate(apiKey.id)} // Use apiKey.id instead of apiKeyId if available
                    disabled={loading}
                    className="px-4 py-2 bg-blue-500 text-white rounded-lg"
                  >
                    {loading ? 'Regenerating...' : 'Regenerate API Key'}
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        ) : (
          <p className="text-gray-500">No API key available.</p>
        )}
      </div>
    </div>
  );
};

export default APIKeyComponent;
