import { SelectBox, Img, Heading } from "../../components";
import React from "react";

const dropDownOptions = [
  { label: "Option1", value: "option1" },
  { label: "Option2", value: "option2" },
  { label: "Option3", value: "option3" },
];

export default function OrdersSection() {
  return (
    <>
      {/* orders section */}
      <div className="mr-6 flex items-start justify-between gap-5 md:mr-0 sm:flex-col">
        <Heading
          size="headingxl"
          as="h1"
          className="ml-1.5 text-[32px] font-bold text-blue_gray-800 lg:text-[27px] md:text-[26px] sm:ml-0 sm:px-5 sm:text-[24px]"
        >
          Orders
        </Heading>
        <SelectBox
          shape="round"
          indicator={<Img src="images/img_icon_theme_color_grey_4.svg" alt="Icon" className="h-[30px] w-[26px]" />}
          getOptionLabel={(e) => (
            <>
              <div className="flex items-center">
                <Img src="images/img_icon_cyan_a700.svg" alt="Icon" className="h-[30px] w-[34px]" />
                <span>{e.label}</span>
              </div>
            </>
          )}
          name="Date Dropdown"
          placeholder={`Filter Period`}
          options={dropDownOptions}
          className="w-[20%] gap-6 self-center rounded-[12px] sm:w-full sm:py-4"
        />
      </div>
    </>
  );
}
