import React, { useState,useEffect } from "react";
import { Helmet } from "react-helmet";
import { useMediaQuery } from "react-responsive";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

// Register Chart.js modules
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export default function DashboardPage() {
  const currentDate = new Date().toISOString().split('T')[0];
  const [startDate, setStartDate] = useState(currentDate);
  const [endDate, setEndDate] = useState(currentDate);
  const [pendingCount, setPendingCount] = useState(0);
  const [activeOrders, setActiveOrders] = useState(0);
  const [completedOrders, setCompletedOrders] = useState(0);
  const [isMobileView, setIsMobileView] = useState(false); // Toggle for views
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const [isSidebarOpen, setSidebarOpen] = useState(true);

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };


  const revenueData = {
    labels: ["May 05", "May 06", "May 07", "May 08", "May 09", "May 10", "May 11"],
    datasets: [
      {
        label: "Revenue ($)",
        data: [12000, 15000, 50000, 20000, 10000, 18000, 70000],
        backgroundColor: "#00aaff",
      },
    ],
  };

  const ordersData = {
    labels: ["May 05", "May 06", "May 07", "May 08", "May 09", "May 10", "May 11"],
    datasets: [
      {
        label: "Orders",
        data: [5, 10, 25, 15, 8, 12, 30],
        backgroundColor: "#ffa500",
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: false,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  useEffect(() => {
    const token = localStorage.getItem('token');

    fetch('/api/orders/Orders/count', {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      }
    })
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then((data) => {
      console.log('API Response:', data);  // Log the entire response
      setPendingCount(data); // Assuming the response is something like { count: <number> }
    })
    .catch((error) => {
      console.error('Error fetching pending orders count:', error);
      setPendingCount('Error fetching data');
    });
  }, []);







  return (
    <>
      <Helmet>
        <title>Admin Dashboard - Sygnus Techlabs</title>
        <meta name="description" content="Welcome to the Sygnus Admin Dashboard." />
        <link rel="icon" href="images/Sygnus logo.png" />
      </Helmet>

     <div className="dashboard-container">
       <div className={`sidebar ${isSidebarOpen ? "open" : "closed"}`}>
         {/* Sidebar content */}
       </div>

       <div className="dashboard-content">
         {/* Dashboard Section */}
         <div className={`dashboard-Admin ${isMobile ? "mobile" : "desktop"}` }  >
         <h2>{isMobile ? "ADMINPAGEDASHBOARD " : "ADMINPAGEDASHBOARD "}</h2>
           <div className={`order-stats ${isMobile ? "mobile-order-stats" : ""}`}>
             <div className="order-stat first-div">
               <h3>Number of Restaurants</h3>
               <p style={{ color: "red", opacity: pendingCount === null ? 0 : 1 }}>
                 {pendingCount !== null ? pendingCount : "Loading..."}
               </p>
             </div>
           </div>
         </div>

         {/* Date Filter Section */}
         <div className={`date-filter ${isMobile ? "mobile-date-filter" : ""}`}>
           <input type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
           <span>-</span>
           <input type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
           <button>Filter By Date</button>
         </div>

         {/* Revenue and Orders Charts Section */}
         <div className={`charts-section ${isMobile ? "mobile-charts-section" : ""}`}>
           <div className="chart-card">
             <div className="chart-header">
               <h3 className="title">Revenue</h3>
               <p className="subtitle">Ventas Totales (Delivered Orders): $102286.20</p>
             </div>
             <Bar data={revenueData} options={options} />
           </div>

           <div className="chart-card">
             <div className="chart-header">
               <h3 className="title">Orders</h3>
               <p className="subtitle">Total Orders (Placed): 755</p>
             </div>
             <Bar data={ordersData} options={options} />
           </div>
         </div>
       </div>
     </div>

    </>
  );
}
