import React, { useState } from "react";
import { MenuItem, Menu, Sidebar } from "react-pro-sidebar";
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

export default function ResponsiveSidebar({ className, ...props }) {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);
  const sidebarLinks = [
    { path: "/dashboard", label: "Dashboard", icon: "img_home_blue_gray_800.svg" },
    { path: "/categories", label: "Categories", icon: "img_icon_theme_color_dark.svg" },
    { path: "/menu-items", label: "Menu Items", icon: "img_icon_theme_color_dark_24x24.svg" },
    { path: "/chefs", label: "Chefs", icon: "img_icon_24x24.svg" },
    { path: "/payment", label: "Payment methods", icon: "img_icon_1.svg" },
    { path: "/orders", label: "Orders", icon: "img_icon_2.svg" },
    { path: "/settings", label: "Settings", icon: "img_television_blue_gray_800.svg" },
    { path: "/api-key", label: "DeveloperTool", icon: "devtool.svg" },
  ];

  return (
    <div>
      {isMobile && !isSidebarOpen && (
  <div className="fixed top-0 left-0 z-50 flex flex-col items-start p-4">
    <img
      src="images/img_logo.png"
      alt="Logo"
      className="w-22 h-16 mb-2"
    />
    <button
      className="bg-cyan-700 text-white p-2 rounded-md"
      onClick={toggleSidebar}
    >
      ☰
    </button>
        </div>
      )}
      {!isMobile && (
        <Sidebar
          {...props}
          className={`flex flex-col h-full bg-white ${className}`}
          style={{ height: "100vh", display: "flex", padding: 0 }}
        >
          <img
            src="images/img_logo.png"
            alt="Logo"
            className="ml-10 h-[90px] w-[64%] object-contain"
          />
          <Menu
            menuItemStyles={{
              button: {
                padding: "20px 20px 20px 44px",
                gap: "25px",
                color: "#464154",
                fontWeight: 500,
                fontSize: "20px",
                "&:hover, &.ps-active": {
                  color: "#0bb5c9",
                  fontWeight: 600,
                },
              },
            }}
            className="mt-[52px] flex w-full flex-col"
          >
            {sidebarLinks.map((link, index) => (
              <Link key={index} to={link.path}>
                <MenuItem
                  icon={
                    <img
                      src={`images/${link.icon}`}
                      alt={`${link.label} Icon`}
                      className="h-[24px] w-[24px]"
                    />
                  }
                >
                  {link.label}
                </MenuItem>
              </Link>
            ))}
          </Menu>
        </Sidebar>
      )}
      {isMobile && (
        <div
          className={`fixed top-0 left-0 h-full bg-white z-50 transition-transform duration-300 ${
            isSidebarOpen ? "translate-x-0" : "-translate-x-full"
          }`}
          style={{
            width: "250px",
            boxShadow: "2px 0px 5px rgba(0, 0, 0, 0.1)",
          }}
        >
          <div className="p-4 flex justify-between items-center bg-cyan-500 text-white">
            <h3 className="font-bold text-lg">Menu</h3>
            <button onClick={toggleSidebar} className="text-white text-xl">
              ✖
            </button>
          </div>
          <Menu
            menuItemStyles={{
              button: {
                padding: "10px 20px",
                color: "#464154",
                fontWeight: 500,
                fontSize: "16px",
                "&:hover, &.ps-active": {
                  color: "#0bb5c9",
                  fontWeight: 600,
                },
              },
            }}
            className="flex flex-col"
          >
            {sidebarLinks.map((link, index) => (
              <Link key={index} to={link.path}>
                <MenuItem onClick={toggleSidebar}>{link.label}</MenuItem>
              </Link>
            ))}
          </Menu>
        </div>
      )}
    </div>
  );
}
