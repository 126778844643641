import React, { useState } from 'react';

const ForgotPassword = () => {
  const [formData, setFormData] = useState({ email: '', otp: '', newPassword: '', confirmPassword: '' });
  const [message, setMessage] = useState('');
  const [showOtpInput, setShowOtpInput] = useState(false);
  const [showPasswordFields, setShowPasswordFields] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.email) {
      setMessage('Please enter your email.');
      return;
    }

    // Step 1: Send OTP if OTP input is not shown
    if (!showOtpInput) {
      try {
        const formDataToSend = new FormData();
        formDataToSend.append('email', formData.email);

        const response = await fetch('api/auth/send-otp', {
          method: 'POST',
          body: formDataToSend,
        });

        const result = await response.text();
        if (response.ok) {
          setShowOtpInput(true);
          setMessage('OTP sent to your email. Please enter it below.');
        } else {
          setMessage(`Error: ${result}`);
        }
      } catch (error) {
        console.error('Error sending OTP:', error);
        setMessage('Failed to send OTP. Please try again.');
      }
    } else if (showOtpInput && formData.otp) {
      // Step 2: Verify OTP
      try {
        const formDataToSend = new FormData();
        formDataToSend.append('email', formData.email);
        formDataToSend.append('otp', formData.otp);

        const response = await fetch('api/auth/verify-otp', {
          method: 'POST',
          body: formDataToSend,
        });

        const result = await response.json();

        if (response.ok) {
          setShowPasswordFields(true);
          setMessage('OTP verified successfully. Please reset your password.');
        } else {
          setMessage(result.error || 'Invalid OTP. Please try again.');
        }
      } catch (error) {
        console.error('Error verifying OTP:', error);
        setMessage('Failed to verify OTP. Please try again.');
      }
    }
  };





const handlesubmitchange = async (e) => {
  e.preventDefault();

  if (!formData.newPassword || !formData.confirmPassword) {
    setMessage('Please enter both passwords.');
    return;
  }

  if (formData.newPassword !== formData.confirmPassword) {
    setMessage('Passwords do not match. Please try again.');
    return;
  }

  try {
    const response = await fetch(`api/auth/reset-password/${formData.email}`, {
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ password: formData.newPassword }),
    });

    if (response.ok) {
      setMessage('Password reset successful.');
    } else {
      const result = await response.text();
      setMessage(`Error: ${result}`);
    }
  } catch (error) {
    console.error('Error resetting password:', error);
    setMessage('Failed to reset password. Please try again.');
  }
};



  return (
    <div style={{ maxWidth: '600px', margin: 'auto', textAlign: 'center', padding: '20px', border: '1px solid #ccc', borderRadius: '10px' }}>
      <h2>Forgot Password</h2>
      <form onSubmit={handleSubmit}>
        <div style={{ marginBottom: '15px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <div style={{ flex: 1 }}>
            <label style={{ display: 'block', marginBottom: '5px', fontWeight: 'bold' }}>Email:</label>
            <input
              type="email"
              name="email"
              placeholder="Enter your email"
              value={formData.email}
              onChange={handleInputChange}
              style={{
                padding: '8px',
                width: '100%',
                borderRadius: '5px',
                border: '1px solid #ccc',
              }}
            />
          </div>
          <button
            type="button"
            onClick={handleSubmit}
            style={{
              backgroundColor: '#007BFF',
              color: 'white',
              padding: '10px 15px',
              border: 'none',
              borderRadius: '5px',
              cursor: 'pointer',
              marginLeft: '10px',
            }}
          >
            {showOtpInput ? 'Verify OTP' : 'Send OTP'}
          </button>
        </div>

        {showOtpInput && (
          <div style={{ marginBottom: '15px' }}>
            <label style={{ display: 'block', marginBottom: '5px', fontWeight: 'bold' }}>Enter OTP:</label>
            <input
              type="text"
              name="otp"
              placeholder="Enter OTP"
              value={formData.otp}
              onChange={handleInputChange}
              style={{
                padding: '8px',
                width: '100%',
                borderRadius: '5px',
                border: '1px solid #ccc',
              }}
            />
          </div>
        )}

        {showPasswordFields && (
          <>
            <div style={{ marginBottom: '15px' }}>
              <label style={{ display: 'block', marginBottom: '5px', fontWeight: 'bold' }}>New Password:</label>
              <input
                type="password"
                name="newPassword"
                placeholder="Enter new password"
                value={formData.newPassword}
                onChange={handleInputChange}
                style={{
                  padding: '8px',
                  width: '100%',
                  borderRadius: '5px',
                  border: '1px solid #ccc',
                }}
              />
            </div>

            <div style={{ marginBottom: '15px' }}>
              <label style={{ display: 'block', marginBottom: '5px', fontWeight: 'bold' }}>Confirm Password:</label>
              <input
                type="password"
                name="confirmPassword"
                placeholder="Confirm your password"
                value={formData.confirmPassword}
                onChange={handleInputChange}
                style={{
                  padding: '8px',
                  width: '100%',
                  borderRadius: '5px',
                  border: '1px solid #ccc',
                }}
              />
            </div>

            <button
              type="submit"
              onClick={handlesubmitchange}
              style={{
                backgroundColor: '#28a745',
                color: 'white',
                padding: '10px 15px',
                border: 'none',
                borderRadius: '5px',
                cursor: 'pointer',
                marginTop: '10px',
              }}
            >
              Reset Password
            </button>
          </>
        )}
      </form>

      {message && <p style={{ marginTop: '15px', color: 'green' }}>{message}</p>}
    </div>
  );
};

export default ForgotPassword;
