import React, { useState } from "react";

const UpdatePasswordForm = () => {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const token = localStorage.getItem('token'); // Retrieve token from localStorage

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validation checks
    if (!currentPassword || !newPassword || !confirmPassword) {
      setResponseMessage("All fields are required.");
      return;
    }

    if (newPassword !== confirmPassword) {
      setResponseMessage("New passwords do not match.");
      return;
    }

    try {
      const response = await fetch(`/api/auth/update-password/${currentPassword}`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
          newPassword,
          confirmPassword,
        }),
      });

      const data = await response.text();
      if (response.ok) {
        setResponseMessage("Password updated successfully!");
      } else {
        setResponseMessage(data || "An error occurred.");
      }
    } catch (error) {
      setResponseMessage("An error occurred. Please try again.");
    }
  };

  return (
    <div className="max-w-md mx-auto p-4 bg-white rounded-lg shadow-md">
      <h2 className="text-2xl font-bold mb-4 text-center">Update Password</h2>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label className="block font-bold text-gray-700" htmlFor="currentPassword">
            Current Password
          </label>
          <input
            type="password"
            id="currentPassword"
            value={currentPassword}
            onChange={(e) => setCurrentPassword(e.target.value)}
            className="w-full px-3 py-2 border border-gray-300 rounded-md"
            placeholder="Enter current password"
            required
          />
        </div>
        <div>
          <label className="block font-bold text-gray-700" htmlFor="newPassword">
            New Password
          </label>
          <input
            type="password"
            id="newPassword"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            className="w-full px-3 py-2 border border-gray-300 rounded-md"
            placeholder="Enter new password"
            required
          />
        </div>
        <div>
          <label className="block font-bold text-gray-700" htmlFor="confirmPassword">
            Confirm Password
          </label>
          <input
            type="password"
            id="confirmPassword"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            className="w-full px-3 py-2 border border-gray-300 rounded-md"
            placeholder="Re-enter new password"
            required
          />
        </div>
        <button
          type="submit"
          className="w-full bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 transition"
        >
          Update Password
        </button>
      </form>
      {responseMessage && (
        <div className="mt-4 text-center text-sm font-medium text-red-500">{responseMessage}</div>
      )}
    </div>
  );
};

export default UpdatePasswordForm;
